import { TextField } from '@mui/material'
import { withStyles } from 'tss-react/mui'

const RoundTextField = withStyles(TextField, () => ({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0',
      '& fieldset': {
        borderColor: 'white',
        borderRadius: '40px',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      // '&.Mui-focused fieldset': {
      //   borderColor: 'yellow',
      // },
    },
  },
  adornedEnd: {
    padding: '0',
    margin: '0',
  },
}))

export default RoundTextField
