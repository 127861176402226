/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { getCookie } from 'cookies-next'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { GetAllTopicsRes } from 'src/types'

export const topicsApi = createApi({
  reducerPath: 'topicsApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    getTopicsList: builder.query<GetAllTopicsRes, void>({
      query: () => ({
        url:
          getCookie('lang') === 'En'
            ? '/api/topics?pagination[pageSize]=50'
            : '/api/topics?pagination[pageSize]=50&locale=es-MX',
        method: 'GET',
      }),
    }),
    getAllTopics: builder.query<GetAllTopicsRes, void>({
      query: () => ({
        url:
          getCookie('lang') === 'En'
            ? '/api/topics?pagination[pageSize]=100&populate=*'
            : '/api/topics?pagination[pageSize]=100&populate=*&locale=es-MX',
        method: 'GET',
      }),
    }),
    getPopularTopics: builder.query<{ id: number; topic: string }[], void>({
      query: () => ({
        url:
          getCookie('lang') === 'En'
            ? '/api/getPopularTopics'
            : '/api/getPopularTopics?locale=es-MX',
        method: 'GET',
      }),
    }),
    userTopics: builder.mutation<any, any>({
      query: (body) => ({
        url: '/api/topics-users',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetTopicsListQuery,
  useGetAllTopicsQuery,
  useGetPopularTopicsQuery,
  useUserTopicsMutation,
} = topicsApi
