/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
// import { getCookie } from 'cookies-next';
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { GetMeInterface } from 'src/types/store'

export const updateProfileApi = createApi({
  reducerPath: 'updateProfileApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    getMe: builder.query<GetMeInterface, number | undefined>({
      query: (id) => ({
        url: `/api/dtl-users/${id}?populate=*`,
        // getCookie('lang')  === 'En'
        //     ? '/api/topics?pagination[pageSize]=50'
        //     : '/api/topics?pagination[pageSize]=50&locale=es-MX',
        method: 'GET',
      }),
    }),
    updateProfile: builder.mutation<
      any,
      {
        username: string
        age: number
        lang: string
        invisionId: string | undefined
      }
    >({
      query: (body) => ({
        url: '/api/updateprofile',
        method: 'POST',
        body,
      }),
    }),
    updateProfilePic: builder.mutation<
      any,
      {
        data: {
          id: number
          avatar: number
        }
      }
    >({
      query: (body) => ({
        url: `/api/dtl-users/${body.data.id}`,
        method: 'PUT',
        body,
      }),
    }),

    updatePassword: builder.mutation<
      any,
      {
        currPassword: string
        newPassword: string
        lang: string
        invisionId: string | undefined
      }
    >({
      query: (body) => ({
        url: '/api/updatePassword',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetMeQuery,
  useUpdateProfileMutation,
  useUpdatePasswordMutation,
  useUpdateProfilePicMutation,
} = updateProfileApi
