/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'

export const becomeAmbassadorApi = createApi({
  reducerPath: 'becomeAmbassadorApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getAllYourStories: builder.query<any, void>({
      query: () => ({
        url: '/api/describe-your-stories',
        method: 'GET',
      }),
    }),
    // TODO: update types
    becomeAmbassador: builder.mutation<any, any>({
      query: (body) => ({
        url: '/api/become-ambassadors',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetAllYourStoriesQuery, useBecomeAmbassadorMutation } =
  becomeAmbassadorApi
