import React from 'react'

const EmptyHeartIcon: React.FC = () => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0029 21.5V21.5L12.9971 21.5C12.8916 21.5006 12.787 21.4804 12.6893 21.4408C12.5921 21.4012 12.5037 21.343 12.4292 21.2697C12.429 21.2694 12.4287 21.2692 12.4285 21.2689L2.33209 11.2037C2.33182 11.2034 2.33155 11.2031 2.33127 11.2029C1.15781 10.0219 0.5 8.42815 0.5 6.76781C0.5 5.10763 1.15769 3.51404 2.33093 2.3331C3.51369 1.15944 5.11597 0.5 6.78683 0.5C8.45818 0.5 10.0609 1.15982 11.2438 2.33411C11.2438 2.33417 11.2439 2.33422 11.2439 2.33427L12.6472 3.73145L13 4.08269L13.3528 3.73145L14.7561 2.33427C14.7561 2.33422 14.7562 2.33417 14.7562 2.33411C15.9391 1.15982 17.5418 0.5 19.2132 0.5C20.884 0.5 22.4863 1.15943 23.669 2.33307C24.8423 3.51401 25.5 5.10761 25.5 6.76781C25.5 8.42818 24.8422 10.022 23.6686 11.2029C23.6684 11.2032 23.6682 11.2034 23.6679 11.2037L13.5715 21.2689C13.5713 21.2692 13.571 21.2695 13.5707 21.2697C13.4962 21.3431 13.4079 21.4012 13.3107 21.4408C13.213 21.4804 13.1084 21.5006 13.0029 21.5Z"
        stroke="white"
      />
    </svg>
  )
}

export default EmptyHeartIcon
