/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { getCookie } from 'cookies-next'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { GetLayoutRes } from 'src/types/store'

export const LayoutApi = createApi({
  reducerPath: 'LayoutApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    getLayoutData: builder.query<GetLayoutRes, void>({
      query: () => ({
        url:
          getCookie('lang') === 'En'
            ? `/api/layouts-data/1?populate=deep`
            : `/api/layouts-data/2?populate=deep&locale=es-MX`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetLayoutDataQuery } = LayoutApi
