import { Roboto } from '@next/font/google'
import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

import { lato, nunito_sans } from './fonts'
import { pxToRem } from './function'
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string
    }
    custom: {
      main: string
      sec: string
      bgColor: string
      borderColor: string
      blackColor: string
      whiteColor: string
      lightFont: string
      drawerBgColor: string
      blueBgColor: string
      pinkBgColor: string
      textFieldBorder: string
    }
    padding: number
    maxWidth: number | string
    fontSize: number
    // fontSize: number
    textPrimary: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      lineHeight: string
    }

    secondaryHeading: {
      fontFamily: string
      fontSize: string
      fontWeight: number
      lineHeight: string
      letterSpacing: string
      textAlign: string
    }
    headerMaxHeight: number
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string
    }
    custom: {
      main: string
      sec: string
      bgColor: string
      borderColor: string
      blackColor: string
      whiteColor: string
      lightFont: string
      drawerBgColor: string
      blueBgColor: string
      pinkBgColor: string
      textFieldBorder: string
    }
    padding: number
    maxWidth: number | string
    fontSize: number
    // fontSize: number
    // fontSize: SpacingOptions
    textPrimary: {
      fontFamily: string
      fontWeight: number
      fontSize: string
      lineHeight: string
    }
    secondaryHeading: {
      fontFamily: string
      fontSize: string
      fontWeight: number
      lineHeight: string
      letterSpacing: string
      textAlign: string
    }
    headerMaxHeight: number
  }
}

export const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
})

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  breakpoints: {
    values: {
      // ? default breakpoints
      // xs: 0,
      // sm: 600,
      // md: 900,
      // lg: 1200,
      // xl: 1536,
      xs: 0,
      sm: 680,
      md: 920,
      lg: 1250,
      xl: 1420,
    },
  },
  custom: {
    main: 'red',
    sec: 'green',
    bgColor: 'rgba(255, 255, 255, 0.3)',
    borderColor: '#000',
    blackColor: '#000',
    whiteColor: '#fff',
    lightFont: '#fff',
    drawerBgColor: '#1C1C1C',
    blueBgColor: '#A0C0E9',
    pinkBgColor: '#DC86A0',
    textFieldBorder: '#C5CED6',
  },
  spacing: 4,
  padding: 4, // ! update it to 16
  maxWidth: 1920,
  headerMaxHeight: 87,
  typography: {
    fontFamily: roboto.style.fontFamily,
  },
  fontSize: 10,
  textPrimary: {
    fontFamily: lato.style.fontFamily,
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
  },
  secondaryHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontSize: pxToRem(72),
    fontWeight: 800,
    lineHeight: pxToRem(83),
    letterSpacing: '0em',
    textAlign: 'left',
  },
})

export default theme
