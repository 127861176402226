/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SliceCaseReducers } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface ReducersType {
  signinToStore: (state: any, { payload }: { payload: any }) => any
  signoutToStore: (state: any) => any
}

const initialState = {
  user: null,
  // token: null,
}

// TODO: update type(type of access token/user info object)
const authSlice = createSlice<any, SliceCaseReducers<any>>({
  initialState,
  name: 'auth-slice',
  reducers: {
    signinToStore: (state, action: any) => ({
      ...state,
      user: action.payload,
    }),

    signoutToStore: () => {
      // Note: This is left intentionally empty.
      // Clearing redux state happens in store.ts.
    },
  },
})

export const { signinToStore, signoutToStore } = authSlice.actions

export default authSlice.reducer
