import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

//  TODO: We dont need this file.
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL || 'http://localhost:1337',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json; charset=UTF-8')
    headers.set(
      'Authorization',
      `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`
    )
    return headers
  },
})

export const baseQueryWithAuth: BaseQueryFn<
  FetchArgs | string,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  return result
}

//  TODO: We dont need this file.
const formBaseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL || 'http://localhost:1337',
  prepareHeaders: (headers) => {
    headers.set(
      'Content-Type',
      'multipart/form-data; boundary=<calculated when request is sent>'
    )
    return headers
  },
})

export const formBaseQueryWithAuth: BaseQueryFn<
  FetchArgs | string,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await formBaseQuery(args, api, extraOptions)
  return result
}
