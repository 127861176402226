/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'

export const uploadMediaAPI = createApi({
  reducerPath: 'uploadMediaAPI',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    uploadPic: builder.mutation<any, any>({
      query: (body) => ({
        url: '/api/upload',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useUploadPicMutation } = uploadMediaAPI
