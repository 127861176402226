import React from 'react'
import Image from 'next/image'
import { GetLayoutRes } from 'src/types/store'
interface Props {
  onClick: () => void
  content: GetLayoutRes
}
function Button({ onClick, content }: Props) {
  return (
    <div className="heartWrapper" onClick={onClick}>
      <div className="effect effectBtn">
        <Image
          width={20}
          height={20}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFAklEQVR4nO2WaU9aaRiG+QczaW2VWndbUQtuBUVbrcgislqrKLhjUaeuI3qcD80w7S+ZZr7MpP+h/dI4P0AEFBfUA4gCsiRt08RnAviCwjksCh2bcCfnE8mb67l4znlvCiWbbLLJJptMxyf5jeGTYm98stU1rwTDveLlrx6RFvcKtZ88nUtv3F3ztame6WCP19lY6rdW1vgnK3MUtzaMfsXrh3G8dmjtoGbwj326kn5t8FO5tswvXX3vk62e+WWr4JNg4BMvg1ekBU/XEng6f4VTwSK4+Qvg4s19cHFn2xKdaWvWtNsb1R9tLDXYmONgfTwKeMMI4PXDcFg7BIc1g3BQMwj7DNXZPl35zx5jsORK8H75Kt8vxdxBcCkGPskKeEXL4O3Sgke4BKcInrcALu48uDrm4IQze+Z89uqdi6v5Kfo8d9vIz7ZG9V/2RjXYWONgZY6BtWEU8PoROKwLwQfADxgq2KcrYf+REizVA2CpUrgstH5eyvA+GfbNj8BJrLt58+DizoGTMwvO9hk4efYKTtp+gePWKdNJ63QlOs/KmqyyNak3Q9bHItbrLlhnXIQfAEt1P1iq+mGvUgG7FX3ftmm9vBTWBnNftO4htT4LTs4MONsR+DQcP50Cx5NJsLM13qNmDf+IPSGwsdTeoPXHyVrvh70qBexV9sEurQ92K3ph+0GPc7dCVpzYvnTl/SXrQgLrHQTWn06D48kUOFom4ahZA3b2S7Cz1J9tzPHPV7G+dw6++/AF7ASeBz1gLu/+Oy68T6JleMUrZzHW+YvhdUHWI+sSsR4APwqAN02ADe36NazvnINvlz+H7bLnsFXafWYu7X5EOoBHpH2LrJ+mbP0l2NkTgF5ScusqOKBHW1cQWt8+hzeXdYO5VA5bJXLYKpLpyPdfqP03Yn0hsfUWAuvMZKwPRKxXIuu9BNYD4AheBlvFUjAVitdIB3ALFm1h69xY68etyPokOFo0IetNEetWZL0+Yj0Crgqty7n10LooYtclxnoAXAabRVLYLJSAqVCEkw7g4i18uWS9/arWhxJbpyWwXhKxvlkkCcEXiMGYL/pC/g9w5y3E1qcya708gfUCMZgKRGC8LwJjvnCPdABnx8y7aOsOIuvhGjAGeNA6eknTYL041ropCN4VfAzUzj9JBzhun+HEfBpbIuBENQCP92msjvdpTML6fWS9C4z3hGC4JwRjrpBDOkBwiNapjxcvpFjrxOUr4YVU8YLAujwp6wh+g9r5IS58cAC2hnbUrPHHs354ZeuhC+mydSmB9cvgBmpn4PEbqXwaJZnYmyZU9saJBBdSGqwXIevi2F0Pgp/D53XCOpWvoqQSK3PsdTprQELr+YTWg/AbeYLXlKsEbxjGYqzTky9fyVg3hl/SWOsbeQLYuMv/nXKdHNQMYolrAIn10qtaF6QHPjwEXYnFK1/kNUAeVQOStJ6bRngUS5USS7p8kdSApKznZgA+PARNgaVcvi7VgIvgBNZz+ZmDR9l52ItlyDqsZxoexVzeg6XZOqzf5X4feJStkm5dKjUAWY8G1wfgc74zfHiIIpkuUfmKupCi4Hn/HzyKqUCki2udEJwP+js3AB7FkC/UpWL9RsGjGKhCXbyXFIHfSHgUA1Wgiw/PvbnwKPpcgY7Iuj7nB4BH0d/h6cLwIXBYv8X5MeBR1m/zJvW3ueb1wJPToQn/kE022WSTDeUG5T934gK0e7ySoAAAAABJRU5ErkJggg=="
          alt={''}
        />
        <span>{content.data.attributes.headerDonateButton.content}</span>
      </div>
    </div>
  )
}

export default Button
