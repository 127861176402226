/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { getCookie } from 'cookies-next'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import {  GetPartnersPageRes } from 'src/types'

export const PartnersApi = createApi({
  reducerPath: 'partnersApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    getPageByName: builder.query<
      GetPartnersPageRes,
      string | string[] | undefined
    >({
      query: (pageName) => ({
        url:
          getCookie('lang') === 'En' 
            ? `/api/partners?filters[pageName][$eq]=${pageName}&populate[pinnedArticles][populate]=articles&populate[partnersTag][populate]=*&populate[partnerLogo][populate]=*&populate[videoPlaceholder][populate]=*&populate[dtlLogo][populate]=*&populate[heroHeader][populate]=*&populate[contentBlock][populate]=*&populate[communityCallToAction][populate]=*&populate[communityWidget][populate]=*`
            : `/api/partners?filters[pageName][$eq]=${
                'mx-' + pageName
              }&populate[pinnedArticles][populate]=articles&populate[heroHeader][populate]=*&populate[partnerLogo][populate]=*&populate[partnersTag][populate]=*&populate[dtlLogo][populate]=*&populate[videoPlaceholder][populate]=*&populate[communityCallToAction][populate]=*&populate[contentBlock][populate]=*&populate[communityWidget][populate]=*&locale=es-MX`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useLazyGetPageByNameQuery } = PartnersApi
