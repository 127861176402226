import React from 'react'
import Button from '@mui/material/Button'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { ButtonComponentProps } from 'src/types'
import meetBg from 'src/assets/svgs/meetBg.png'
import meetBgEs from 'src/assets/images/meetBgEs.png'
import { getCookie } from 'cookies-next'
const MeetButton: React.FC<ButtonComponentProps> = (
  props: ButtonComponentProps
) => {
  const {
    type,
    text,
    className = '',
    isSubmitBtn = false,
    disabled,
    onClick,
    size = 'large',
    startIcon,
    loading = false,
    gutter = false,
    gutterLeft = false,
    gutterRight = false,
    gutterTop = false,
    gutterBottom = false,
    backgroundColor = 'white',
    wrapperClass,
  } = props

  const [lang, setLang] = React.useState('')
  const { classes } = useStyles({
    backgroundColor: backgroundColor,
  })
  React.useEffect(() => {
    if (
      typeof window === 'object' &&
      (window.location.href.includes('mx') ||
        window.location.href.includes('quit') ||
        window.location.href.includes('quiat'))
    ) {
      localStorage.setItem('lang', 'Es')
      setLang('Es')
    } else {
      localStorage.setItem('lang', 'En')
      setLang('En')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof window === 'object' ? window.location.href : null])

  return (
    <div
      className={clsx(
        classes.btnWrapper,
        gutter && classes.gutter,
        gutterLeft && classes.gutterLeft,
        gutterRight && classes.gutterRight,
        gutterTop && classes.gutterTop,
        gutterBottom && classes.gutterBottom,
        wrapperClass,
        lang === 'Es' && classes.mxWidth
      )}
    >
      <Button
        role="button"
        aria-label={`${text}`}
        size={size}
        variant={type}
        className={clsx(classes.root, className)}
        type={isSubmitBtn ? 'submit' : 'button'}
        disabled={disabled ?? loading}
        onClick={onClick}
        startIcon={startIcon}
        sx={{
          whiteSpace: 'noWrap',
        }}
      >
        {text}
      </Button>
    </div>
  )
}

const useStyles = makeStyles<{ backgroundColor: string }>()((theme) => ({
  root: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: theme.custom.blackColor,
    border: `0px solid ${theme.custom.borderColor}`,
    borderRadius: '30.5px',
    paddingLeft: '20px',
    paddingRight: '20px',
    '&:hover': {
      backgroundImage: `url(${
        getCookie('lang') === 'En' ? meetBg.src : meetBgEs.src
      })`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      color: 'transparent',

      border: `0px solid ${theme.custom.borderColor}`,

      span: {
        display: 'block',
      },
    },
  },
  btnWrapper: {
    padding: theme.spacing(0.35),
    borderRadius: '30.5px',
    width: '172.5px',
    height: 'auto',
    border: `1.3px solid ${theme.custom.borderColor}`,

    '&:hover': {},
  },
  gutter: {
    margin: theme.spacing(2),
  },
  gutterLeft: {
    marginLeft: theme.spacing(2),
  },
  gutterRight: {
    marginRight: theme.spacing(2),
  },
  gutterTop: {
    marginTop: theme.spacing(2),
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  mxWidth: {
    width: '155.5px',
  },
}))

export default MeetButton
