import type { AnyAction, Reducer } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import { authenticationApi } from './ApiCall/authApiCalls'
import { topicsApi } from './ApiCall/topicsAPI'
import combineReducers from 'src/store/combineReducer'
import { ArticlesApi } from './ApiCall/articleAPI'
import { PagesApi } from './ApiCall/pagesAPI'
import { dtlOriginalApi } from './ApiCall/dtlOriginalApi'
import { becomeAmbassadorApi } from './ApiCall/becomeAmbassador'
import { volunteerApi } from './ApiCall/volunteerAPI'
import { CommentsApi } from './ApiCall/commentAPI'
import { communityApi } from './ApiCall/getCommunityData'
import { researchReportApi } from './ApiCall/getResearchReport'
import { careersApi } from './ApiCall/careersAPI'
import { authorApi } from './ApiCall/authorAPI'
import { brandCollabApi } from './ApiCall/brandCollabAPI'
import { HomeCardApi } from './ApiCall/homeCardAPI'
import { formApi } from './ApiCall/formAPI'
import { quizzesApi } from './ApiCall/quizzesApi'
import { recentPress } from './ApiCall/getRecentPress'
import { campaignPartner } from './ApiCall/getCampaignPartners'
import { uploadAPI } from './ApiCall/fileUploadAPI'
import { LayoutApi } from './ApiCall/layoutAPI'
import { updateProfileApi } from './ApiCall/updateProfileAPI'
import { uploadMediaAPI } from './ApiCall/uploadMediaAPI'
import { sensitiveWordAPI } from './ApiCall/sensitiveWordAPI'
import { PartnersApi } from './ApiCall/partnersAPI'

const rootReducer: Reducer = (
  state: ReturnType<typeof combineReducers>,
  action: AnyAction
) => {
  if (action.type === 'auth-slice/signoutToStore') {
    state = {} as ReturnType<typeof combineReducers>
  }
  return combineReducers(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      // for middleware quizzesApi.middleware
      authenticationApi.middleware,
      topicsApi.middleware,
      authorApi.middleware,
      ArticlesApi.middleware,
      PagesApi.middleware,
      becomeAmbassadorApi.middleware,
      volunteerApi.middleware,
      dtlOriginalApi.middleware,
      becomeAmbassadorApi.middleware,
      CommentsApi.middleware,
      communityApi.middleware,
      researchReportApi.middleware,
      careersApi.middleware,
      brandCollabApi.middleware,
      HomeCardApi.middleware,
      formApi.middleware,
      quizzesApi.middleware,
      recentPress.middleware,
      campaignPartner.middleware,
      uploadAPI.middleware,
      LayoutApi.middleware,
      updateProfileApi.middleware,
      uploadMediaAPI.middleware,
      sensitiveWordAPI.middleware,
      PartnersApi.middleware
    ),
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
