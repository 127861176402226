import { combineReducers } from 'redux'
import auth from 'src/store/reducer/authSlice'
import { ArticlesApi } from './ApiCall/articleAPI'
import zenMode from 'src/store/reducer/zenSlice'
import selectedDropDownValue from 'src/store/reducer/selectedDropDownValueSlice'
import { authenticationApi } from './ApiCall/authApiCalls'
import { topicsApi } from './ApiCall/topicsAPI'
import { PagesApi } from './ApiCall/pagesAPI'
import { dtlOriginalApi } from './ApiCall/dtlOriginalApi'
import { becomeAmbassadorApi } from './ApiCall/becomeAmbassador'
import { volunteerApi } from './ApiCall/volunteerAPI'
import { communityApi } from './ApiCall/getCommunityData'
import { CommentsApi } from './ApiCall/commentAPI'
import { researchReportApi } from './ApiCall/getResearchReport'
import { careersApi } from './ApiCall/careersAPI'
import { authorApi } from './ApiCall/authorAPI'
import { brandCollabApi } from './ApiCall/brandCollabAPI'
import activeTopicSlice from './reducer/activeTopicSlice'
import { HomeCardApi } from './ApiCall/homeCardAPI'
import { formApi } from './ApiCall/formAPI'
import { quizzesApi } from './ApiCall/quizzesApi'
import { recentPress } from './ApiCall/getRecentPress'
import { campaignPartner } from './ApiCall/getCampaignPartners'
import { uploadAPI } from './ApiCall/fileUploadAPI'
import { LayoutApi } from './ApiCall/layoutAPI'
import { updateProfileApi } from './ApiCall/updateProfileAPI'
import { uploadMediaAPI } from './ApiCall/uploadMediaAPI'
import sensitiveWordsSlice from './reducer/sensitiveWordsSlice'
import { sensitiveWordAPI } from './ApiCall/sensitiveWordAPI'
import { PartnersApi } from './ApiCall/partnersAPI'

export default combineReducers({
  auth,
  zenModeState: zenMode,
  selectedDropDownValueState: selectedDropDownValue,
  activeTopics: activeTopicSlice,
  sensitiveWords: sensitiveWordsSlice,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [authorApi.reducerPath]: authorApi.reducer,
  [topicsApi.reducerPath]: topicsApi.reducer,
  [ArticlesApi.reducerPath]: ArticlesApi.reducer,
  [PagesApi.reducerPath]: PagesApi.reducer,
  [dtlOriginalApi.reducerPath]: dtlOriginalApi.reducer,
  [becomeAmbassadorApi.reducerPath]: becomeAmbassadorApi.reducer,
  [volunteerApi.reducerPath]: volunteerApi.reducer,
  [CommentsApi.reducerPath]: CommentsApi.reducer,
  [communityApi.reducerPath]: communityApi.reducer,
  [researchReportApi.reducerPath]: researchReportApi.reducer,
  [careersApi.reducerPath]: careersApi.reducer,
  [brandCollabApi.reducerPath]: brandCollabApi.reducer,
  [HomeCardApi.reducerPath]: HomeCardApi.reducer,
  [formApi.reducerPath]: formApi.reducer,
  [quizzesApi.reducerPath]: quizzesApi.reducer,
  [recentPress.reducerPath]: recentPress.reducer,
  [campaignPartner.reducerPath]: campaignPartner.reducer,
  [uploadAPI.reducerPath]: uploadAPI.reducer,
  [LayoutApi.reducerPath]: LayoutApi.reducer,
  [updateProfileApi.reducerPath]: updateProfileApi.reducer,
  [uploadMediaAPI.reducerPath]: uploadMediaAPI.reducer,
  [sensitiveWordAPI.reducerPath]: sensitiveWordAPI.reducer,
  [PartnersApi.reducerPath] : PartnersApi.reducer
})
