import React from 'react'

const RedHeartIcon: React.FC = () => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 22C12.8289 22.001 12.6594 21.9683 12.501 21.9039C12.3426 21.8395 12.1986 21.7446 12.0771 21.6247L1.97747 11.5562C0.710613 10.2817 0 8.56094 0 6.76781C0 4.97467 0.710613 3.25395 1.97747 1.97944C3.25424 0.711834 4.98375 0 6.78683 0C8.58991 0 10.3194 0.711834 11.5962 1.97944L13 3.37713L14.4038 1.97944C15.6806 0.711834 17.4101 0 19.2132 0C21.0162 0 22.7458 0.711834 24.0225 1.97944C25.2894 3.25395 26 4.97467 26 6.76781C26 8.56094 25.2894 10.2817 24.0225 11.5562L13.9229 21.6247C13.8014 21.7446 13.6574 21.8395 13.499 21.9039C13.3406 21.9683 13.1711 22.001 13 22Z"
        fill="#FF0020"
      />
    </svg>
  )
}

export default RedHeartIcon
