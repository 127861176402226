import * as React from 'react'
import { makeStyles } from 'tss-react/mui'
import { TextField } from '@mui/material'

const FormTextField: React.FC = () => {
  const { classes } = useStyles()
  return (
    <TextField
      id="outlined-basic"
      label="Outlined"
      variant="outlined"
      className={classes.root}
    />
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0),
    background: theme.custom.whiteColor,
    border: `1px solid ${theme.custom.textFieldBorder}`,
    borderRadius: 112,
  },
}))

export default FormTextField
