import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeTopic: {
    id: -1,
  },
}

const activeTopicsSlice = createSlice({
  initialState,
  name: 'activeTopic',
  reducers: {
    activeTopicHandler: (state, action) => {
      return {
        ...state,
        activeTopic: action.payload,
      }
    },
  },
})

export const { activeTopicHandler } = activeTopicsSlice.actions

export default activeTopicsSlice.reducer
