import React from 'react'

function FillDownArrow() {
  return (
    <svg
      width="32"
      height="21"
      viewBox="0 0 32 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6999 19.8233L31.0365 4.22339C32.146 2.92554 31.2238 0.923764 29.5163 0.923763L2.84311 0.923764C1.13561 0.923763 0.213366 2.92554 1.32292 4.22339L14.6595 19.8233C15.4578 20.7571 16.9015 20.7571 17.6999 19.8233Z"
        fill="#1C1C1C"
      />
    </svg>
  )
}

export default FillDownArrow
