/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { getCookie } from 'cookies-next'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { CareersAPIRes, CareersResData } from 'src/types'

export const careersApi = createApi({
  reducerPath: 'careersApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    jobListing: builder.query<CareersAPIRes, void>({
      query: () => ({
        url:
          getCookie('lang') === 'En'
            ? '/api/careers?locale=en'
            : '/api/careers?locale=es-MX',
        method: 'GET',
      }),
    }),
    jobDetail: builder.query<{ data: CareersResData }, number | string>({
      query: (id: string | number) => ({
        url: `/api/careers/${id}`,
        method: 'GET',
      }),
    }),
    applyJob: builder.mutation<any, any>({
      query: (body) => ({
        url: '/api/job-applications',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useJobListingQuery, useJobDetailQuery, useApplyJobMutation } =
  careersApi
