import React from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { RainbowButtonProps } from 'src/types'
import { nunito_sans } from '@styles/fonts'

const RainbowBorderButton: React.FC<RainbowButtonProps> = (
  props: RainbowButtonProps
) => {
  const {
    text,
    className = '',
    active = false,
    onClick,
    gutter = false,
    gutterLeft = false,
    gutterRight = false,
    gutterTop = false,
    gutterBottom = false,
  } = props

  const { classes } = useStyles()

  return (
    <button
      role="button"
      aria-label={`${text} button`}
      aria-pressed={active}
      type="button"
      className={clsx(
        classes.root,
        gutter && classes.gutter,
        gutterLeft && classes.gutterLeft,
        gutterRight && classes.gutterRight,
        gutterTop && classes.gutterTop,
        gutterBottom && classes.gutterBottom,
        className
      )}
      onClick={onClick}
    >
      <div
        className={clsx(
          classes.btnInnerContainer,
          active ? classes.lightBgColor : classes.darkBgColor
        )}
      >
        <Typography
          variant="body2"
          className={clsx(
            classes.text,
            active ? classes.darkFont : classes.lightFont
          )}
        >
          {text}
        </Typography>
      </div>
    </button>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 'auto',
    height: '34px',
    border: `none`,
    borderRadius: '30.5px',
    padding: theme.spacing(0.25),
    background:
      'linear-gradient(122deg, rgba(220,134,160,1) 0%, rgba(160,192,233,1) 25%, rgba(234,165,57,1) 50%, rgba(132,140,201,1) 75%, rgba(99,209,105,1) 100%)',
    cursor: 'pointer',
  },
  btnInnerContainer: {
    width: '100%',
    height: '100%',
    borderRadius: '30.5px',
    textAlign: 'center',
    display: 'grid',
    placeContent: 'center',
    padding: theme.spacing(2, 4),
  },
  lightBgColor: {
    backgroundColor: theme.custom.whiteColor,
    color: theme.custom.blackColor,
  },
  darkBgColor: {
    backgroundColor: theme.custom.blackColor,
  },
  text: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
  },
  darkFont: {
    color: theme.custom.blackColor,
  },
  lightFont: {
    color: theme.custom.whiteColor,
  },
  gutter: {
    margin: theme.spacing(2),
  },
  gutterLeft: {
    marginLeft: theme.spacing(2),
  },
  gutterRight: {
    marginRight: theme.spacing(2),
  },
  gutterTop: {
    marginTop: theme.spacing(2),
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
}))

export default RainbowBorderButton
