import { createApi } from '@reduxjs/toolkit/query/react'
import { ResearchPapersI } from 'src/types'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'

export const researchReportApi = createApi({
  reducerPath: 'researchReportApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getResearchReport: builder.query<ResearchPapersI, void>({
      query: () => ({
        url: 'api/research-reports?populate=deep',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetResearchReportQuery } = researchReportApi
