/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { PostFormRes } from 'src/types/store'

export const formApi = createApi({
  reducerPath: 'formApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    postFormData: builder.mutation<
      PostFormRes,
      {
        data: {
          email: string
          name: string
          phonenumber: string
          subject: string
          message: string
          request_type: string
        }
      }
    >({
      query: (body) => ({
        url: '/api/customer-requests',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { usePostFormDataMutation } = formApi
