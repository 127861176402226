/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Link from 'next/link'
// import Image from 'next/image'
import Box from '@mui/material/Box'
import {
  Grid,
  IconButton,
  Avatar,
  Popover,
  Typography,
  Button,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { DownArrowIcon } from 'src/assets/svgs'
// import SubMenu from './subMenu'
import ButtonComponent from 'src/components/shared/atoms/button'
import { signoutToStore } from 'src/store/reducer/authSlice'
// import Drawer from './drawer'
import { useRouter } from 'next/router'
import { ItsAnEmergencyCard, DonateNowCard } from './subMenu/endCard'
// import SelectLanguage from './intlMenu'
import { HamburgerMenuIcon, SearchIcon } from 'src/assets/svgs'
import clsx from 'clsx'
import useStyles from './styles'
// import ConfidentialSupport from 'src/components/layout/confidentialSupportComponent'
import MeetButton from 'src/components/shared/atoms/button/meetButton'
import HeartBtn from 'src/components/shared/atoms/button/heartBtn'
import { GetLayoutRes } from 'src/types/store'
import defaultAvatar from 'src/assets/images/defaultAvatar.jpeg'
import { setCookie, hasCookie, getCookie, deleteCookie } from 'cookies-next'
import { v4 as uuid } from 'uuid'
import dynamic from 'next/dynamic'
import Image from 'next/image'

const ConfidentialSupport = dynamic(
  () => import('src/components/layout/confidentialSupportComponent'),
  {
    ssr: false,
  }
)

const Drawer = dynamic(() => import('./drawer'), {
  ssr: false,
})

const SubMenu = dynamic(() => import('./subMenu'), {
  ssr: false,
})

interface IProps {
  content: GetLayoutRes
}
const Header: React.FC<IProps> = ({ content }) => {
  const [auth, setAuth] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [drawerState, setDrawerState] = useState(false)
  const [iNeedHelpOpen, setINeedHelpOpen] = useState(false)
  const [iWantToHelpOpen, setIWantToHelpOpen] = useState(false)
  const [loggedInUserAvatar, setLoggedInUserAvatar] = useState<string>('')
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const router = useRouter()

  const anonymousUserTokenCheck = hasCookie('anonymousUserToken')
  if (!anonymousUserTokenCheck) {
    setCookie('anonymousUserToken', uuid())
  }

  useEffect(() => {
    // if (!window) return

    if (
      typeof window === 'object' &&
      window.localStorage.getItem('dtl_token')
    ) {
      setAuth(true)
    }
    // }, [])
  }, [
    typeof window !== 'undefined' && window?.localStorage.getItem('dtl_token'),
  ])
  useEffect(() => {
    if (window) {
      const loggedInUserJSON =
        typeof window === 'object' ? localStorage.getItem('dtl_token') : null

      if (loggedInUserJSON) {
        setLoggedInUserAvatar(JSON.parse(loggedInUserJSON).avatar)
      }
    }
  }, [
    typeof window !== 'undefined' && window?.localStorage.getItem('dtl_token'),
  ])
  const drawerHandler: () => void = (): void => {
    setIWantToHelpOpen(false)
    setINeedHelpOpen(false)
    setDrawerState(!drawerState)
  }

  const iNeedHelpHandler: () => void = (): void => {
    setIWantToHelpOpen(false)
    setINeedHelpOpen(!iNeedHelpOpen)
  }

  const iWantToHelpHandler: () => void = (): void => {
    setINeedHelpOpen(false)
    setIWantToHelpOpen(!iWantToHelpOpen)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorEl) {
      setAnchorEl(null)
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleLogout = (): void => {
    if (typeof window === 'object') {
      window.localStorage.removeItem('dtl_token')
      deleteCookie('dtl_token')
    }
    if (typeof window === 'object') {
      window.localStorage.removeItem('user')
    }
    dispatch(signoutToStore(null))
    if (typeof window === 'object') {
      window.location.reload()
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const meetNewFriendBtnHandler = () => {
    // check if user is signed in
    document.location.href =
      getCookie('lang') === 'En'
        ? 'https://forums.ditchthelabel.org/'
        : 'http://ayuda.qle.mx/'
    setINeedHelpOpen(false)
    setIWantToHelpOpen(false)
    // const userToken =
    //   typeof window === 'object'
    //     ? window.localStorage.getItem('dtl_token')
    //     : null

    // if (!userToken) {
    //   router.push('/auth/login')
    // } else {
    //   document.location.href = 'https://forums.ditchthelabel.org/'
    //   setINeedHelpOpen(false)
    //   setIWantToHelpOpen(false)
    // }
  }

  const recommendMeHandler = () => {
    // check if user is signed in
    const userToken =
      typeof window === 'object'
        ? window.localStorage.getItem('dtl_token')
        : null

    if (!userToken) {
      router.push('/auth/login')
      handleClose()
      return
    }

    router.push('/recommend-me')
    handleClose()
  }

  const profileBtnHandler = (path: string) => {
    // check if user is signed in
    const userToken =
      typeof window === 'object'
        ? window.localStorage.getItem('dtl_token')
        : null

    if (!userToken) {
      router.push('/auth/login')
      handleClose()
      return
    }

    router.push(path)
    handleClose()
  }
  const favouriteBtnHandler = (path: string) => {
    // check if user is signed in
    const userToken =
      typeof window === 'object'
        ? window.localStorage.getItem('dtl_token')
        : null

    if (!userToken) {
      router.push('/auth/login')
      handleClose()
      return
    }

    router.push(path)
    handleClose()
  }
  return (
    <div>
      {content ? <ConfidentialSupport content={content} /> : ''}

      <Box component="header" className={classes.rootHeader}>
        <Grid container className={classes.innerContainer}>
          <Grid
            container
            item
            xs={5}
            md={5}
            lg={5}
            className={classes.menuContainer}
          >
            <Grid
              item
              md={1}
              onClick={drawerHandler}
              className={classes.hamburgerMenuIcon}
            >
              <HamburgerMenuIcon />
            </Grid>
            <Grid item md={11} className={classes.linksContainer}>
              <Button
                disableRipple
                className={clsx(
                  classes.btnlinks,
                  !iNeedHelpOpen && classes.linkHover
                )}
                onClick={iNeedHelpHandler}
              >
                <span className={clsx(iNeedHelpOpen && classes.addBlueBgColor)}>
                  {content.data.attributes.HeadermenuList[0].menuHeading}
                </span>
              </Button>
              <Button
                disableRipple
                className={clsx(
                  classes.btnlinks,
                  !iWantToHelpOpen && classes.linkHover
                )}
                onClick={iWantToHelpHandler}
              >
                <span
                  className={clsx(iWantToHelpOpen && classes.addPinkBgColor)}
                >
                  {content.data.attributes.HeadermenuList[1].menuHeading}
                </span>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={2} md={2} lg={2} className={classes.logoContainer}>
            <Box className={classes.mobileLogo}>
              <Link href="/">
                <Grid className={classes.imgContainerMob}>
                  <Image
                    src={content.data.attributes.dtlLogo.data.attributes.url}
                    alt="Logo"
                    fill
                  />
                </Grid>
              </Link>
            </Box>
            <Box className={classes.desktopLogo}>
              <Link href="/">
                <Grid className={classes.imgContainer}>
                  <Image
                    src={content.data.attributes.dtlLogo.data.attributes.url}
                    alt="Logo"
                    height={58}
                    width={237}
                  />
                </Grid>
              </Link>
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={5}
            md={5}
            lg={5}
            className={classes.buttonsContainer}
          >
            <Link href="/search">
              <IconButton className={classes.searchBtn}>
                <SearchIcon />
              </IconButton>
            </Link>
            {/* <Box className={classes.intlBox}>
              <SelectLanguage />
            </Box> */}
            {auth ? (
              <Box>
                <button onClick={handleClick} className={classes.avatarButton}>
                  <div className={classes.iconElement}>
                    <Avatar
                      src={
                        loggedInUserAvatar === ''
                          ? defaultAvatar?.src
                          : loggedInUserAvatar
                      }
                      sx={{ marginRight: '8px', width: '40px', height: '40px' }}
                    />
                    <DownArrowIcon />
                  </div>
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  className={classes.popover}
                >
                  <Typography
                    className={clsx(
                      classes.pointerCursor,
                      classes.popoverBorder
                    )}
                    onClick={() =>
                      profileBtnHandler(content.data.attributes.profileBtn.href)
                    }
                  >
                    {content.data.attributes.profileBtn.content}
                  </Typography>
                  <Typography
                    className={clsx(
                      classes.pointerCursor,
                      classes.popoverBorder
                    )}
                    onClick={() =>
                      favouriteBtnHandler(
                        content.data.attributes.favouriteBtn.href
                      )
                    }
                  >
                    {content.data.attributes.favouriteBtn.content}
                  </Typography>
                  <Typography
                    className={clsx(
                      classes.pointerCursor,
                      classes.popoverBorder
                    )}
                    onClick={recommendMeHandler}
                  >
                    {content.data.attributes.recommendDropdownBtn.content}
                  </Typography>
                  <Typography
                    className={clsx(
                      classes.pointerCursor,
                      classes.popoverBorder
                    )}
                    onClick={meetNewFriendBtnHandler}
                  >
                    {content.data.attributes.goToCommunityBtn.content}
                  </Typography>
                  <Typography
                    className={classes.pointerCursor}
                    onClick={handleLogout}
                  >
                    {content.data.attributes.logoutBtn.content}
                  </Typography>
                </Popover>
              </Box>
            ) : (
              <Box className={classes.buttonBox}>
                <MeetButton
                  size="medium"
                  type="outlined"
                  text={
                    content.data.attributes.headerMeetnewfriendButton.content
                  }
                  onClick={meetNewFriendBtnHandler}
                />
                <HeartBtn
                  content={content}
                  onClick={() => {
                    router.push(
                      content.data.attributes.headerDonateButton.href
                        ? content.data.attributes.headerDonateButton.href
                        : '#'
                    )
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>

        {drawerState ? (
          <Drawer content={content} drawerHandler={drawerHandler} />
        ) : null}
        {iNeedHelpOpen ? (
          <SubMenu
            heading={
              content.data.attributes.HeadermenuList[0].menuSubHeader.heading
            }
            subHeading={
              content.data.attributes.HeadermenuList[0].menuSubHeader
                .description
            }
            menuDataList={
              content.data.attributes.HeadermenuList[0].menuListItem
            }
            className={classes.addBlueBgColor}
            closeSubMenu={() => {
              setINeedHelpOpen(false)
            }}
            button={
              <ButtonComponent
                type={'contained'}
                text={
                  content.data.attributes.HeadermenuList[0].menuSubHeader.button
                    .content
                }
                className={classes.getHelpBtn}
                gradient
                wrapperClass={classes.btnWrapper}
                onClick={() => {
                  router.push(
                    content.data.attributes.HeadermenuList[0].menuSubHeader
                      .button.href
                      ? content.data.attributes.HeadermenuList[0].menuSubHeader
                          .button.href
                      : '#'
                  )
                  setINeedHelpOpen(false)
                }}
              />
            }
            endCard={
              <ItsAnEmergencyCard
                content={
                  content.data.attributes.HeadermenuList[0].menuListItem[
                    content.data.attributes.HeadermenuList[0].menuListItem
                      .length - 1
                  ]
                }
                closeSubMenu={() => {
                  setINeedHelpOpen(false)
                }}
              />
            }
          />
        ) : null}
        {iWantToHelpOpen ? (
          <SubMenu
            heading={
              content.data.attributes.HeadermenuList[1].menuSubHeader.heading
            }
            subHeading={
              content.data.attributes.HeadermenuList[1].menuSubHeader
                .description
            }
            menuDataList={
              content.data.attributes.HeadermenuList[1].menuListItem
            }
            className={classes.addPinkBgColor}
            closeSubMenu={() => {
              setIWantToHelpOpen(false)
            }}
            button={
              <ButtonComponent
                type={'contained'}
                text={
                  content.data.attributes.HeadermenuList[1].menuSubHeader.button
                    .content
                }
                className={classes.getHelpBtn}
                gradient
                wrapperClass={classes.btnWrapper}
                onClick={() => {
                  router.push(
                    content.data.attributes.HeadermenuList[1].menuSubHeader
                      .button.href
                      ? content.data.attributes.HeadermenuList[1].menuSubHeader
                          .button.href
                      : '/'
                  )
                  setIWantToHelpOpen(false)
                }}
              />
            }
            endCard={
              <DonateNowCard
                content={
                  content.data.attributes.HeadermenuList[1].menuListItem[
                    content.data.attributes.HeadermenuList[0].menuListItem
                      .length - 1
                  ]
                }
                closeSubMenu={() => {
                  setIWantToHelpOpen(false)
                }}
              />
            }
          />
        ) : null}
      </Box>
    </div>
  )
}

export default Header
