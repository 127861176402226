import * as React from 'react'
import { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { SnackbarProvider } from 'notistack'
import store from 'src/store/store'
import Layout from 'src/components/layout/main'
import theme from 'src/styles/theme'
import createEmotionCache from 'src/styles/createEmotionCache'
import '../styles/globals.scss'
import Script from 'next/script'
// import HeadTag from 'src/components/layout/head'
// import { useRouter } from 'next/router'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  // const router = useRouter()
  return (
    <CacheProvider value={emotionCache}>
      <Provider store={store}>
        {/* <HeadTag
          title={
            router.route.slice(1)
              ? `Ditch the Label - ${router.route
                  .slice(1)
                  .replace(/-/g, ' ')
                  .toUpperCase()}`
              : `Ditch the Label - Home`
          }
        /> */}
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            autoHideDuration={3000}
          >
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>

      <Script src="/userway.js" strategy="afterInteractive" async></Script>

      <Script
        id=""
        dangerouslySetInnerHTML={{
          __html: `
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "w38ydzmb"
          };
        `,
        }}
      ></Script>
      {/* <Script src="https://www.googletagmanager.com/gtag/js?id=G-F5CWCGS4JT" />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-F5CWCGS4JT');
        `}
      </Script> */}
    </CacheProvider>
  )
}
