import { createApi } from '@reduxjs/toolkit/query/react'
import { CommunityI } from 'src/types'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'

export const communityApi = createApi({
  reducerPath: 'communityApi',
  baseQuery: baseQueryWithAuth,
  // baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:1337/' }),

  endpoints: (builder) => ({
    getCommunityData: builder.mutation<
      CommunityI[],
      { topic: number; locale: string }
    >({
      query: ({ topic, locale }) => ({
        url: `api/join-community/${topic}?locale=${locale}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetCommunityDataMutation } = communityApi
