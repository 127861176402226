/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { getCookie } from 'cookies-next'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { getAllQuiz } from 'src/types/store'

export const quizzesApi = createApi({
  reducerPath: 'quizzesApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getQuizzes: builder.query<getAllQuiz, number>({
      query: (page) => ({
        url:
          getCookie('lang') === 'En'
            ? `/api/quizzes?pagination[page]=${page}&pagination[pageSize]=6&populate=*`
            : `/api/quizzes?pagination[page]=${page}&pagination[pageSize]=6&populate=*&locale=es-MX`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetQuizzesQuery } = quizzesApi
