import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'

const useStyles = makeStyles()((theme) => ({
  rootHeader: {
    width: '100%',
    height: 'auto',
    position: 'fixed',
    left: 0,
    right: 0,
    top: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.custom.bgColor,
    backdropFilter: 'blur(47.5px)',
    borderTop: `1px solid ${theme.custom.blackColor}`,
    borderBottom: `1px solid ${theme.custom.blackColor}`,
    // zIndex: 99999, ///can be used later
    zIndex: 999,
    [theme.breakpoints.down('md')]: {
      top: 27,
    },
  },
  innerContainer: {
    width: '100%',
    maxWidth: theme.maxWidth,
    height: 'inherit',
    maxHeight: theme.headerMaxHeight,
    padding: theme.spacing(0, 16),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  hamburgerMenuIcon: {
    cursor: 'pointer',
  },
  logoContainer: {
    display: 'grid',
    placeContent: 'center',
    img: {
      // marginTop: '8px',
    },
  },

  imgContainerMob: {
    position: 'relative',
    width: '73px',
    height: '45px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '1.5rem',
      transform: 'scale(1.3)',
    },
  },
  imgContainer: {
    position: 'relative',
    top: '3px',
    img: {
      transform: 'scale(1.9)',
    },
  },
  mobileLogo: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  desktopLogo: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  searchBtn: {
    marginRight: theme.spacing(1),
  },
  intlBox: {
    '@media (max-width:1370px)': {
      // display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      // display: 'none',
    },
  },
  avatarButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  iconElement: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '6px',
  },
  popover: {
    marginTop: '16px',
    border: '1px solid rgba(28, 28, 28, 0.14)',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.05)',
    zIndex: 999999,

    '& .mui-style-3bmhjh-MuiPaper-root-MuiPopover-paper': {
      borderRadius: '32px',
    },

    '& p': {
      padding: theme.spacing(4, 4),
    },
    '& .MuiPaper-root': {
      zIndex: 999999,
    },
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  popoverBorder: {
    borderBottom: '1px solid rgba(28, 28, 28, 0.14)',
  },
  notificationIcon: {
    margin: theme.spacing(0, 2, 0, 0),

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '@media (max-width:1370px)': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  links: {
    display: 'inline-block',
    height: '87px',
    lineHeight: '87px',
    span: {
      display: 'inline-block',
      height: '100%',
      padding: theme.spacing(0, 4),
      color: theme.custom.blackColor,
    },
  },
  btnlinks: {
    display: 'inline-block',
    height: '87px',
    lineHeight: '87px',
    padding: '0px',
    span: {
      fontWeight:400,
      display: 'inline-block',
      fontSize:'16px',
      height: '100%',
      padding: theme.spacing(0, 4),
      color: theme.custom.blackColor,
    },
  },
  linkHover: {
    '&>span:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  addBlueBgColor: {
    backgroundColor: theme.custom.blueBgColor,
  },
  addPinkBgColor: {
    backgroundColor: theme.custom.pinkBgColor,
  },
  DrawerContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    padding: theme.spacing(0, 4),
    color: theme.custom.lightFont,
    backgroundColor: theme.custom.blackColor,
  },
  DrawerTopBar: {
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(8),
    paddingBottom: 0,
    borderBottom: `1px solid ${theme.custom.whiteColor}`,
  },
  donateBtn: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    textAlign: 'center',
    color: theme.custom.lightFont,
    backgroundColor: theme.custom.blackColor,
    ':hover': {
      background: theme.custom.blackColor,
    },
  },
  cardBorder: {
    border: 'none',
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
  },
  getHelpBtn: {
    width: 'fit-content',
    minWidth: 176,
    height: 50,
    paddingTop: theme.spacing(3),
    backgroundColor: theme.custom.blackColor,
    color: theme.custom.lightFont,
    '&:hover': {
      background: 'white',
      color: 'black',
    },
  },
  btnWrapper: {
    width: 'fit-content !important',
    height: '54px !important',
  },
}))

export default useStyles
