import * as React from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Header from './header'
import Footer from './footer'
import { SmileIcon, ZenModeIcon } from 'src/assets/svgs'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { zenModeHandler } from 'src/store/reducer/zenSlice'
import { Nunito_Sans } from '@next/font/google'
import { useGetLayoutDataQuery } from 'src/store/ApiCall/layoutAPI'
import zenMx from 'src/assets/images/zenMx.png'
import userWayIcon from 'src/assets/images/userWayIcon.svg'

import Image from 'next/image'
import { getCookie } from 'cookies-next'
import CookieConsent from '../shared/atoms/cookie-consent'
type Props = {
  children?: React.ReactNode
}
const nunito_sans = Nunito_Sans({
  weight: ['400', '700', '800'],
  subsets: ['latin'],
})

const Layout: React.FC<Props> = ({ children }: Props) => {
  const { classes } = useStyles()
  const [smileOpen, setSimleOpen] = React.useState<boolean>(false)
  const [isBrowser, setIsBrowser] = React.useState(false)
  // const isBrowser = typeof window === 'object' ? true : false
  const lang = getCookie('lang')
  // const [lang, setLang] = React.useState<CookieValueTypes>()

  const { data } = useGetLayoutDataQuery()
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(zenModeHandler())
  }
  React.useEffect(() => {
    setIsBrowser(typeof window === 'object')
  }, [])
  // const Test = () => {
  //   const collection = document.getElementsByClassName(
  //     'intercom-lightweight-app'
  //   )
  //   const openChatIcon = document.getElementsByClassName('intercom-dfosxs ')
  //   const openChatPointer = document.getElementsByClassName('intercom-zi3hra')
  //   console.log(collection[0])
  //   for (let i = 0; i < collection.length; i++) {
  //     collection[i].style.opacity = !smileOpen ? 1 : 0
  //     collection[i].style.pointerEvents = !smileOpen ? 'auto' : 'none'
  //   }
  //   for (let i = 0; i < openChatIcon.length; i++) {
  //     openChatIcon[i].style.opacity = !smileOpen ? 1 : 0
  //     openChatIcon[i].style.pointerEvents = !smileOpen ? 'auto' : 'none'
  //   }
  //   for (let i = 0; i < openChatPointer.length; i++) {
  //     openChatPointer[i].style.pointerEvents = !smileOpen ? 'auto' : 'none'
  //   }
  //   console.log(collection[0])
  // }

  return (
    <>
      <Box component="div" className={nunito_sans.className}>
        {data && data.data ? <Header content={data} /> : ''}

        <Box component="div" className={classes.childrenContainer}>
          {children}
        </Box>
        {data && data.data ? <Footer content={data} /> : ''}
      </Box>
      <Box component="div" className={classes.root}>
        <div className={clsx(classes.smiley, smileOpen && classes.smileyOpen)}>
          <SmileIcon
            onClick={() => {
              // Test()
              setSimleOpen(!smileOpen)
            }}
          />
          {isBrowser && lang === 'En' ? (
            <ZenModeIcon
              className={smileOpen ? classes.zenModeIcon : classes.displayNone}
              onClick={handleClick}
            />
          ) : (
            smileOpen && (
              <Box className={classes.zenMxWrapper}>
                <Image
                  src={zenMx}
                  height={30}
                  width={30}
                  alt=""
                  onClick={handleClick}
                  className={
                    smileOpen ? classes.zenModeIconMx : classes.displayNone
                  }
                />
              </Box>
            )
          )}
           
              <Box
                id="accessibilityWidget123"
                tabIndex={0}
                className={classes.userWayWrapper}
                style={{
                  zIndex: smileOpen ? 1 : -1,
                  opacity: smileOpen ? 1 : 0,
                  overflow: 'hidden',
                }}
              >
                <Image
                  src={userWayIcon}
                  height={44}
                  width={44}
                  alt=""
                  id="accessibilityWidget123"
                  className={
                    smileOpen ? "" : classes.displayNone
                  }
                />
              </Box>
           
        </div>
        {typeof window === 'object' ? <CookieConsent /> : ''}
      </Box>
    </>
  )
}

const useStyles = makeStyles()((theme) => ({
  '& .intercom-lightweight-app-launcher': {
    bottom: '33px !important',
    right: '113px !important',
  },
  root: {
    transition: 'all 0.5ms ease',
  },
  childrenContainer: {
    width: '100%',
    height: 'auto',
    minHeight: '100vh',
    marginTop: theme.headerMaxHeight + 2,
    padding: theme.spacing(0),
  },
  displayNone: {
    display: 'none',
  },

  smiley: {
    width: '58px',
    height: '55px',
    borderRadius: 40,
    fontSize: '2.5rem',
    position: 'fixed',
    top: 'calc(100vh - 85px) !important',
    left: 'calc(100vw - 105px) !important',
    background: 'black',
    zIndex: 99999999999999,
    border: '0.5px solid rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    animation: 'bounce-enter-animation 1s linear',

    '@keyframes bounce-enter-animation': {
      '0%': {
        transform: 'translateY(-220px)',
      },
      '25%': {
        transform: 'translateY(0px)',
      },
      '50%': {
        transform: 'translateY(-150px)',
      },
      '75%': {
        transform: 'translateY(-100px)',
      },

      '100%': {
        transform: 'scale(1)',
      },
    },
  },

  smileyOpen: {
    width: '180px',
    height: '55px',
    backgroundColor: 'black',
    borderRadius: 40,
    left: 'calc(100vw - 225px) !important',
    display: 'flex',
    border: '0.5px solid rgba(0, 0, 0, 0.25)',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    animation: 'all 1s ease',
    zIndex: 999999999,
  },

  zenModeIcon: {
    position: 'fixed',
    left: 'calc(100vw - 160px) !important',
    marginTop: '4px',
    transition: 'all 0.3s ease',
    zIndex: 999,
    '&:hover': {
      transform: 'scale(1.06)',
    },
  },
  
  zenModeIconMx: {
    position: 'fixed',
    left: 'calc(100vw - 160px) !important',
    marginTop: '10px',
    marginLeft: '9px',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  zenMxWrapper: {
    position: 'fixed',
    left: 'calc(100vw - 160px) !important',
    marginTop: '3.2px',
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    background: 'white',
  },
  userWayWrapper: {
    position: 'fixed',
    left: 'calc(100vw - 102px) !important',
    marginTop: '4.2px',
    height: '46px',
    width: '46px',
    borderRadius: '50%',
    background: 'white',
    transition: 'all 0.3s ease',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    zIndex: 999,
    '&:hover': {
      transform: 'scale(1.06)',
    },
  },
}))

export default Layout
