import React from 'react'

export default function Play({
  onClickHandler,
}: {
  onClickHandler?: () => void
}): JSX.Element {
  return (
    <svg
      width="27"
      height="30"
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClickHandler}
    >
      <path
        d="M24.709 12.4019C26.709 13.5566 26.709 16.4433 24.709 17.598L4.89555 29.0373C2.89555 30.192 0.395548 28.7487 0.395548 26.4393L0.395549 3.56068C0.39555 1.25128 2.89555 -0.192097 4.89555 0.962603L24.709 12.4019Z"
        fill="white"
      />
    </svg>
  )
}
