import { makeStyles } from 'tss-react/mui'
import { pxToRem } from '@styles/function'

const useStyles = makeStyles()((theme) => ({
  menuDropdown: {
    width: '100%',
    background: 'white',
    borderRadius: '40px',
    margin: 0,
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: pxToRem(19.6),
      paddingLeft: pxToRem(24),
      borderColor: 'none',
      border: 'none',
      height: '60px',
      [theme.breakpoints.down('sm')]: {
        height: '61px',
        borderColor: 'none',
      },
      '& fieldset': {
        // borderColor: 'black',

        border: 0,
        borderColor: 'none',

        height: '60px',
        [theme.breakpoints.down('sm')]: {
          height: '60px',
        },
      },
      '&:hover fieldset': {
        borderColor: 'none',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '0px',
        borderColor: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderBottomRightRadius: '40px',
        borderColor: 'none',
      },
    },
  },
  menuDropdownZenMode: {
    border: '1px solid black',
  },
  menuIconContainer: {
    position: 'absolute',
    right: '20px',
    height: '60px',
    display: 'flex',
    alignItems: 'center ',
  },
}))

export default useStyles
