import { makeStyles } from 'tss-react/mui'
import Noise from 'src/assets/images/campaigns/noise.png'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  root: {
    maxWidth: theme.maxWidth,

    margin: theme.spacing(0, 16),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 0),
    },
  },
  innerContainer: {
    background: `url(${Noise.src}) #47506D`,
    mixBlendMode: 'multiply',
    borderRadius: '40px',
    [theme.breakpoints.down('md')]: {
      borderRadius: '30px',
    },
  },
  zenModeRootContainer: {
    background: 'black !important',
  },
  heading: {
    padding: theme.spacing(1),
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '168.02%',
    textTransform: 'uppercase',
    textDecoration: 'no-underline',
    color: '#FFFFFF',
    '@media (max-width:1350px)': {
      fontSize: '12px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  emojisContainer: {
    '& ul': {
      width: '79%',
      margin: '100px auto 0',
      padding: 0,
      '& li': {
        display: 'inline-block',
        listStyleType: 'none',
        margin: '-35px -5px',
        // '&:nth-child(1) button': {
        //   paddingTop: '20px',
        // },

        button: {
          position: 'relative',
          background: 'transparent',
          border: 'none',
          color: 'white',
          fontFamily: 'Verdana',
          fontWeight: 'bold',
          fontSize: '50px',
          cursor: 'pointer',
          padding: 0,
        },
      },
    },
  },
  brandsLogoContainer: {
    display: 'flex',
    animation: 'marquee 15s linear infinite',
    '@keyframes marquee': {
      '0%': {
        transform: 'translateX(100%)',
      },
      '100%': {
        transform: 'translateX(-100%)',
      },
    },
    '&>*': {
      marginRight: '40px',
    },
  },
  mxbrandsLogoContainer: {
    display: 'flex',

    '&>*': {
      marginRight: '40px',
    },
  },
  headingIcons: {
    padding: theme.spacing(1),
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '168.02%',
    textTransform: 'uppercase',
    textDecoration: 'no-underline',
    color: '#FFFFFF',
    '@media (max-width:1350px)': {
      fontSize: '12px',
    },
    '@media (max-width:985px)': {
      fontSize: '9px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  textCenter: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  signUpheading: {
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '134%',
    /* identical to box height, or 27px */
    marginTop: theme.spacing(4.5),
    textAlign: 'start',
    textTransform: 'uppercase',
    textDecoration: 'no-underline',
    color: '#FFFFFF',
  },
  safeSpaceHeading: {
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '134%',
    marginTop: '24px',
    textAlign: 'start',
    textTransform: 'uppercase',
    textDecoration: 'no-underline',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '32px',
    },
  },
  safeSpaceText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '168%',
    textAlign: 'start',
    /* identical to box height, or 27px */
    opacity: '0.7',
    textTransform: 'uppercase',
    textDecoration: 'no-underline',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  borderLine: {
    background: 'rgba(255, 255, 255, 0.3) !important',
  },

  learnMore: {
    color: 'rgba(255, 255, 255, 1)',
    borderColor: 'rgba(255, 255, 255, 1)',
    border: '1.5px solid',
    width: '117px',
    height: '34px',
    borderRadius: '130px',
    fontWeight: '700',
    fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: '18px',
    '@media (max-width:1350px)': {
      marginLeft: '-10px',
    },
    '@media (max-width:1250px)': {
      marginLeft: '0px',
    },
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:1250px)': {
      svg: {
        width: '40px',
        '@media (max-width:1250px)': {
          width: '30px',
        },
        [theme.breakpoints.down('md')]: {
          width: '45px',
        },
      },
      img: {
        width: '20px',
        '@media (max-width:1250px)': {
          width: '25px',
        },
        '@media (max-width:1050px)': {
          width: '20px',
        },
        [theme.breakpoints.down('md')]: {
          width: '30px',
          marginBottom: '17px',
        },
      },
    },
  },
  smallIcons: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      '@media (max-width:1250px)': {
        width: '30px',
      },
      [theme.breakpoints.down('md')]: {
        width: '65px',
      },
    },
  },
  links: {
    padding: theme.spacing(1),
    textAlign: 'center',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    // lineHeight: '168.02%',
    /* or 20px */

    textTransform: 'uppercase',

    color: '#FFFFFF',
    textDecorationLine: 'none',
    opacity: '0.7',
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
      marginTop: '5px',
      textAlign: 'start',
    },
  },
  policyLinks: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px !important',
    padding: '0 4px',
    lineHeight: '168.02%',
    textDecorationLine: 'underline',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
    },
  },
  policyLinksBottom: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '168.02%',
    opacity: '.87',
    display: 'flex',
    alignItems: 'center',
    a: {
      color: '#000000',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '155%',
      textDecoration: 'none',
      borderBottom: '1px solid #000000',
      opacity: '.87',
    },
    textDecorationLine: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  signUpButton: {
    background:
      'linear-gradient(277.78deg, #DC86A0 1.46%, #A0C0E9 34.77%, #EAA539 67.45%, #63D169 90.58%, #848CC9 102.02%)',
    border: '1px solid #FFFFFF',
    borderRadius: '40px',
    height: '64px',
    width: '138px',
    color: '#000000',
    backgroundSize: '300% 300%',
    backgroundPosition: '0 50%',
    animation: 'moveGradient 5s alternate infinite',

    '@keyframes moveGradient': {
      '50%': {
        backgroundPosition: '100% 50%',
      },
    },
  },
  signupField: {
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    boxSizing: 'border-box',
    borderRadius: '40px',
    width: '100%',

    color: '#FFFFFF',
    height: '70px',
  },
  signUpLabel: {
    border: '1px solid #FFFFFF',
    borderRadius: '40px',
    color: '#FFFFFF',
  },

  wrapperClass: {
    width: 'fit-content !important',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      marginTop: '20px',
    },
  },
  joinNowButton: {
    border: '2px solid transparent',
    borderRadius: '130px',
    color: '#FFFFFF',
    '--border-width': '2px',
    '--border-angle': '0.0turn',
    width: '134px',
    height: '50px',
    background: 'black',
    '&:hover': {
      background: 'none',
    },
    ' &::after': {
      position: 'absolute',
      content: '""',
      top: 'calc(-1 * var(--border-width))',
      left: 'calc(-1 * var(--border-width))',
      zIndex: '-1',
      width: 'calc(100% + var(--border-width) * 2)',
      height: 'calc(100% + var(--border-width) * 2)',
      // background:
      // 'linear-gradient(0deg, #1C1C1C, #1C1C1C),linear-gradient(180deg, #DC86A0 0%, #A0C0E9 27.6%, #EAA539 54.69%, #848CC9 83.33%, #63D169 100%)',
      // 'linear-gradient(   60deg, hsla(342, 55%, 69%, 1), hsla(214, 62%, 77%, 1),hsla(37, 81%, 57%, 1), hsla(233, 39%, 65%, 1), hsl(44, 85%, 66%),hsla(123, 54%, 60%, 1)',
      // 'linear-gradient(   60deg, hsl(224, 85%, 66%), hsl(269, 85%, 66%),hsl(314, 85%, 66%), hsl(359, 85%, 66%), hsl(44, 85%, 66%),hsl(89, 85%, 66%),hsl(134, 85%, 66%),hsl(179, 85%, 66%))',
      // hsla(342, 55%, 69%, 1)
      // hsla(214, 62%, 77%, 1)
      // hsla(37, 81%, 57%, 1)
      // hsla(123, 54%, 60%, 1)
      // hsla(233, 39%, 65%, 1)

      // borderImageSource:
      //   'linear-gradient(180deg, #DC86A0 0%, #A0C0E9 27.6%, #EAA539 54.69%, #848CC9 83.33%, #63D169 100%)',

      backgroundSize: '300% 300%',
      backgroundPosition: '0 50%',
      borderRadius: '130px',
      animation: 'moveGradient 4s alternate infinite',
    },

    //     hsla(342, 55%, 69%, 1)
    // hsla(214, 62%, 77%, 1)
    // hsla(37, 81%, 57%, 1)
    // hsla(233, 39%, 65%, 1)
    // hsla(123, 54%, 60%, 1)

    '@keyframes moveGradient': {
      // to: {
      //  '--border-angle': '1turn',
      // }
      '50%': {
        backgroundPosition: '100% 50%',
      },
    },
    // animation: 'bg-spin 5s linear infinite',
    '@property --border-angle': {
      syntax: '<angle>',
      inherits: true,
      initialvalue: '0turn',
    },
  },

  footerFont: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '155%',
  },
  tiktok: {
    cursor: 'pointer',
  },
}))

export default useStyles
