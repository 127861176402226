import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  zenMode: false,
}

const zenSlice = createSlice({
  initialState,
  name: 'zen-slice',
  reducers: {
    zenModeHandler: (state) => {
      return {
        ...state,
        zenMode: !state.zenMode,
      }
    },
  },
})

export const { zenModeHandler } = zenSlice.actions

export default zenSlice.reducer
