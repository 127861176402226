/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import {
  CreateCommentRes,
  CreateEmojiBody,
  CreateEmojiRes,
  CreateUserCommentEmojiBody,
  CreateUserCommentEmojiRes,
  DeleteUserCommentEmojiBody,
  DeleteUserCommentEmojiRes,
  FindEmojiRes,
  StrapiCommentType,
  StrapiDeleteCommentType,
  StrapiEditCommentType,
} from 'src/types/store'

export const CommentsApi = createApi({
  reducerPath: 'commentsApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    createComment: builder.mutation<CreateCommentRes, StrapiCommentType>({
      query: (body) => ({
        url: `/api/comments`,
        method: 'POST',
        body,
      }),
    }),
    editComment: builder.mutation<CreateCommentRes, StrapiEditCommentType>({
      query: (body) => ({
        url: `/api/comments/${body.data.id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteComment: builder.mutation<CreateCommentRes, StrapiDeleteCommentType>({
      query: (body) => ({
        url: `/api/comments/${body.data.id}`,
        method: 'DELETE',
      }),
    }),
    deleteMulipleComments: builder.mutation<{ count: number }, { id: number }>({
      query: (body) => ({
        url: `/api/customcomments/deleteMany`,
        method: 'POST',
        body,
      }),
    }),
    findEmoji: builder.mutation<FindEmojiRes, string>({
      query: (unified) => ({
        url: `/api/reactions?filters[unified][$eq]=${unified}`,
        method: 'GET',
      }),
    }),
    createEmoji: builder.mutation<CreateEmojiRes, CreateEmojiBody>({
      query: (body) => ({
        url: `/api/reactions`,
        method: 'POST',
        body,
      }),
    }),

    addUserCommentEmoji: builder.mutation<
      CreateUserCommentEmojiRes,
      CreateUserCommentEmojiBody
    >({
      query: (body) => ({
        url: `/api/user-comment-emojis`,
        method: 'POST',
        body,
      }),
    }),

    deleteUserCommentEmoji: builder.mutation<
      DeleteUserCommentEmojiRes,
      DeleteUserCommentEmojiBody
    >({
      query: (body) => ({
        url: `/api/usercommentemoji/${body.commentId}/${body.reactionId}/${body.userId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useCreateCommentMutation,
  useEditCommentMutation,
  useDeleteCommentMutation,
  useFindEmojiMutation,
  useCreateEmojiMutation,
  useAddUserCommentEmojiMutation,
  useDeleteUserCommentEmojiMutation,
  useDeleteMulipleCommentsMutation,
} = CommentsApi
