/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { getCookie } from 'cookies-next'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { GetDTLOriginalRes } from 'src/types'

export const dtlOriginalApi = createApi({
  reducerPath: 'dtlOriginalApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    getAllDTLOriginal: builder.query<GetDTLOriginalRes, void>({
      query: () => ({
        url:
          getCookie('lang') === 'En'
            ? '/api/dtl-originals?populate=*'
            : '/api/dtl-originals?populate=*&locale=es-MX',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetAllDTLOriginalQuery } = dtlOriginalApi
