/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { formBaseQueryWithAuth } from 'src/store/ApiCall/baseQuery'

export const uploadAPI = createApi({
  reducerPath: 'uploadAPI',
  baseQuery: formBaseQueryWithAuth,
  endpoints: (builder) => ({
    uploadFile: builder.mutation<any, any>({
      query: (body) => ({
        url: '/api/upload',
        method: 'POST',
        body: body,
      }),
    }),
  }),
})

export const { useUploadFileMutation } = uploadAPI
