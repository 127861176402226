/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { getCookie } from 'cookies-next'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import {
  GetAllArticleRes,
  GetAllArticles,
  GetPageRes,
  FeaturedArticleRes,
  getPopularArticle,
  GetCommentsInterface,
  AlgoliaRecommendedArticles,
} from 'src/types/store'

export const ArticlesApi = createApi({
  reducerPath: 'articlesApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    getArticlePage: builder.query<GetPageRes, string | string[] | undefined>({
      query: (pageName) => ({
        url:
          getCookie('lang') === 'En'
            ? `/api/article-schemas?filters[name][$eq]=${pageName}&populate=deep&locale=en`
            : `/api/article-schemas?filters[name][$eq]=${
                'mx-' + pageName
              }&populate=deep&locale=es-MX`,
        method: 'GET',
      }),
    }),
    getAllArticles: builder.query<GetAllArticleRes, number>({
      query: (page) => ({
        url: `/api/articles?pagination[page]=${page}&pagination[pageSize]=6&populate=*`,
        method: 'GET',
      }),
    }),

    getAllArticlesByAuthorIdAndTopicId: builder.mutation<
      GetAllArticleRes,
      {
        topicId: number
        authorId: number
        page: number
        searchText: string
        activeOrder: number
        userId: number
      }
    >({
      query: (body) => ({
        url:
          getCookie('lang') === 'En'
            ? `/api/customcomments/articles?data[user]=${body.userId}&data[topicId]=${body.topicId}&data[authorId]=${body.authorId}&data[searchText]=${body.searchText}&data[orderBy]=${body.activeOrder}&pagination[page]=${body.page}&pagination[pageSize]=12&locale=en`
            : `/api/customcomments/articles?data[user]=${body.userId}&data[topicId]=${body.topicId}&data[authorId]=${body.authorId}&data[searchText]=${body.searchText}&data[orderBy]=${body.activeOrder}&pagination[page]=${body.page}&pagination[pageSize]=12&locale=es-MX`,
        method: 'GET',
      }),
    }),

    getAllFavouriteArticlesByAuthorIdAndTopicId: builder.mutation<
      GetAllArticleRes,
      {
        topicId: number
        authorId: number
        page: number
        searchText: string
        activeOrder: number
        userId: number
      }
    >({
      query: (body) => ({
        url: `/api/getFavouriteArticles/${body.userId}?data[topicId]=${body.topicId}&data[authorId]=${body.authorId}&data[searchText]=${body.searchText}&data[orderBy]=${body.activeOrder}&pagination[page]=${body.page}&pagination[pageSize]=12`,
        method: 'GET',
      }),
    }),

    getFavouriteArticleIdByUser: builder.query<number[], number>({
      query: (userId) => ({
        url: `/api/getFavArticleId/${userId}`,
        method: 'GET',
      }),
    }),
    getLazyFavouriteArticleIdByUser: builder.query<number[], number>({
      query: (userId) => ({
        url: `/api/getFavArticleId/${userId}`,
        method: 'GET',
      }),
    }),
    getLazyArticlesByIDs: builder.query<GetAllArticles, string>({
      query: (query) => ({
        url:
          getCookie('lang') === 'En'
            ? `/api/articles?${query}`
            : `/api/articles?${query}&locale=es-MX`,

        method: 'GET',
      }),
    }),
    getArticle: builder.mutation<GetAllArticles, string | string[] | undefined>(
      {
        query: (slug) => ({
          url:
            getCookie('lang') === 'En'
              ? `/api/articles?filters[slug][$eq]=${slug}&populate[author][populate][0]=Picture&populate[images][populate][0] = url&populate[brandLogo][populate][0] = url&populate[tags][populate][0] = topic &populate[article_ratings][populate]=*&populate[comments][populate][0]=id&locale=en`
              : `/api/articles?filters[slug][$eq]=${slug}&populate[author][populate][0]=Picture&populate[images][populate][0] = url&populate[brandLogo][populate][0] = url&populate[tags][populate][0] = topic &populate[article_ratings][populate]=*&populate[comments][populate][0]=id&locale=es-MX`,
          method: 'GET',
        }),
      }
    ),
    getComments: builder.mutation<GetCommentsInterface[], { id: number[] }>({
      query: (body) => ({
        url: `/api/getTopLevelComments`,
        method: 'POST',
        body,
      }),
    }),

    disLikeArticle: builder.mutation<
      { id: number },
      { articleId: number; userId: number }
    >({
      query: (body) => ({
        url: `/api/articlelike/delete/${body.userId}/${body.articleId}`,
        method: 'DELETE',
      }),
    }),
    likeArticle: builder.mutation<
      { id: number },
      { articleId: number; userId: number }
    >({
      query: (body) => ({
        url: `/api/article-likes`,
        method: 'POST',
        body,
      }),
    }),
    addRatingArticle: builder.mutation<
      { id: number },
      { data: { article: number; dtl_user: number; rating: string } }
    >({
      query: (body) => ({
        url: `/api/article-ratings`,
        method: 'POST',
        body,
      }),
    }),
    updateRatingArticle: builder.mutation<
      { id: number },
      { article: number; dtl_user: number; rating: string }
    >({
      query: (body) => ({
        url: `api/articlerating/update`,
        method: 'POST',
        body,
      }),
    }),
    getPopularArticles: builder.query<
      getPopularArticle[],
      { dropDownTitle: string; loggedInUser: number }
    >({
      query: (body) => ({
        url:
          getCookie('lang') === 'En'
            ? `api/populararticle/${body.loggedInUser}/${body.dropDownTitle}?locale=en`
            : `api/populararticle/${body.loggedInUser}/${body.dropDownTitle}?locale=es-MX`,
        method: 'GET',
      }),
    }),
    getSearchArticles: builder.query<GetAllArticleRes, void>({
      query: () => ({
        url: '/api/articles?fields=title,tags,favourite_count&populate=*',
        method: 'GET',
      }),
    }),
    // ? featured articles using custom api
    getFeaturedArticles: builder.query<FeaturedArticleRes, string>({
      query: (topic) => ({
        url: `/api/feature-article?${topic}`,
        method: 'GET',
      }),
    }),
    updateArticles: builder.mutation<
      { data: { id: number } },
      { data: { id: number; like_count: number; article_like_count: number } }
    >({
      query: (body) => ({
        url: `/api/articles/${body.data.id}`,
        method: 'PUT',
        body,
      }),
    }),
    getAllFeaturedArticles: builder.query<FeaturedArticleRes, void>({
      query: () => ({
        url:
          getCookie('lang') === 'En'
            ? `/api/featured-articles?populate[article][populate]=*&populate[topic][populate]=id&locale=en`
            : `/api/featured-articles?populate[article][populate]=*&populate[topic][populate]=id&locale=es-MX`,
        method: 'GET',
      }),
    }),
    getAlgoliaRecommendedArticles: builder.query<
      AlgoliaRecommendedArticles,
      void
    >({
      query: () => ({
        url:
          getCookie('lang') === 'En'
            ? `/api/algolia-popular-articles?populate=*&locale=en`
            : `/api/algolia-popular-articles?populate=*&locale=es-MX`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetArticlePageQuery,
  useGetAllArticlesQuery,
  useGetArticleMutation,
  useGetSearchArticlesQuery,
  useGetAllArticlesByAuthorIdAndTopicIdMutation,
  useGetAllFavouriteArticlesByAuthorIdAndTopicIdMutation,
  useDisLikeArticleMutation,
  useLikeArticleMutation,
  useAddRatingArticleMutation,
  useUpdateRatingArticleMutation,
  useGetPopularArticlesQuery,
  useGetCommentsMutation,
  useUpdateArticlesMutation,
  useGetFeaturedArticlesQuery,
  useGetAlgoliaRecommendedArticlesQuery,
  useGetAllFeaturedArticlesQuery,
  useLazyGetLazyArticlesByIDsQuery,
  useGetFavouriteArticleIdByUserQuery,
  useLazyGetLazyFavouriteArticleIdByUserQuery,
} = ArticlesApi
