import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sensitiveWords: [],
}

const sensitiveWordsSlice = createSlice({
  name: 'sensitiveWordAPI',
  initialState,
  reducers: {
    setSensitiveWordsData: (state, action) => {
      state.sensitiveWords = action.payload
    },
  },
})

export default sensitiveWordsSlice.reducer
