import React from 'react'
import Button from '@mui/material/Button'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { ButtonComponentProps } from 'src/types'
import { nunito_sans } from '@styles/fonts'

const ButtonComponent: React.FC<ButtonComponentProps> = (
  props: ButtonComponentProps
) => {
  const {
    type,
    text,
    className = '',
    isSubmitBtn = false,
    disabled,
    onClick,
    size = 'large',
    startIcon,
    loading = false,
    gutter = false,
    gutterLeft = false,
    gutterRight = false,
    gutterTop = false,
    gutterBottom = false,
    backgroundColor = 'white',
    gradient = false,
    wrapperClass,
  } = props

  const { classes } = useStyles({ backgroundColor: backgroundColor })

  return (
    <div
      className={clsx(
        gradient && classes.btnWrapperRoot,
        gutter && classes.gutter,
        gutterLeft && classes.gutterLeft,
        gutterRight && classes.gutterRight,
        gutterTop && classes.gutterTop,
        gutterBottom && classes.gutterBottom,
        wrapperClass
      )}
    >
      <Button
        role="button"
        aria-label={`${text}`}
        size={size}
        variant={type}
        className={clsx(classes.root, className)}
        type={isSubmitBtn ? 'submit' : 'button'}
        disabled={disabled ?? loading}
        onClick={onClick}
        startIcon={startIcon}
        sx={{
          whiteSpace: 'noWrap',
        }}
      >
        {text}
      </Button>
    </div>
  )
}

const useStyles = makeStyles<{ backgroundColor: string }>()(
  (theme, { backgroundColor }) => ({
    root: {
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      color: theme.custom.blackColor,
      border: `1px solid ${theme.custom.borderColor}`,
      borderRadius: '30.5px',
      background: backgroundColor,
      fontFamily: nunito_sans.style.fontFamily,
      fontWeight: 700,
      paddingLeft: '20px',
      paddingRight: '20px',
      [theme.breakpoints.down('md')]: {
        padding: '8px',
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },
    btnWrapperRoot: {
      fontFamily: nunito_sans.style.fontFamily,
      fontWeight: 700,
      padding: theme.spacing(0.5),
      background:
        'linear-gradient(122deg, rgba(220,134,160,1) 0%, rgba(160,192,233,1) 25%, rgba(234,165,57,1) 50%, rgba(132,140,201,1) 75%, rgba(99,209,105,1) 100%)',
      cursor: 'pointer',
      borderRadius: '30.5px',
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0.4),
      },
    },
    gutter: {
      margin: theme.spacing(2),
    },
    gutterLeft: {
      marginLeft: theme.spacing(2),
    },
    gutterRight: {
      marginRight: theme.spacing(2),
    },
    gutterTop: {
      marginTop: theme.spacing(2),
    },
    gutterBottom: {
      marginBottom: theme.spacing(2),
    },
  })
)

export default ButtonComponent
