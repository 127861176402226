import HamburgerMenuIcon from './icons/hamburger'
import SearchIcon from './icons/searchIcon'
import CrossIcon from './icons/crossIcon'
import ArrowIcon from './icons/arrow'
import ArrowIconUp from './arrowIconUp'

import LongArrowIcon from './icons/longArrow'
import FillDownArrow from './icons/FillDownArrow'
import DownArrowIcon from './icons/downArrow'
import BellIcon from './icons/bell'
import EmptyHeartIcon from './icons/emptyHeart'
import RedHeartIcon from './icons/redHeart'
import Play from './icons/play'
import StopVideoIcon from './icons/stopVideo'
import { VolumeIcon, VolumeMuteIcon } from './icons/soundIcons'
import SmileIcon from './icons/smile'
import ZenModeIcon from './icons/zenModeIcon'
import EyeIcon from './icons/eye'
import EyeClosedIcon from './icons/eyeClosed'

export {
  HamburgerMenuIcon,
  SearchIcon,
  CrossIcon,
  ArrowIcon,
  LongArrowIcon,
  FillDownArrow,
  DownArrowIcon,
  BellIcon,
  EmptyHeartIcon,
  RedHeartIcon,
  Play as PlayIcon,
  StopVideoIcon,
  VolumeIcon,
  VolumeMuteIcon,
  SmileIcon,
  ZenModeIcon,
  ArrowIconUp,
  EyeIcon,
  EyeClosedIcon,
}
