import { createApi } from '@reduxjs/toolkit/query/react'
import { getCookie } from 'cookies-next'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { BrandCollabRes } from 'src/types'

export const brandCollabApi = createApi({
  reducerPath: 'brandCollabApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update type
    getAllCollabVideos: builder.query<BrandCollabRes, void>({
      query: () => ({
        url:
          getCookie('lang') === 'En'
            ? '/api/brand-collabs?populate=*'
            : '/api/brand-collabs?populate=*&locale=es-MX',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetAllCollabVideosQuery } = brandCollabApi
