import { createApi } from '@reduxjs/toolkit/query/react'
import { RecentPressAPI } from 'src/types'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'

export const recentPress = createApi({
  reducerPath: 'recentPressApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getRecentPressApi: builder.query<RecentPressAPI, void>({
      query: () => ({
        url: 'api/recent-presses?populate=deep',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetRecentPressApiQuery } = recentPress
