import React from 'react'

interface CrossIconPropsType {
  className?: string
  color?: string
}

const CrossIcon: React.FC<CrossIconPropsType> = ({
  className,
  color = 'white',
}: CrossIconPropsType) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ''}
    >
      <rect
        x="1.91992"
        y="0.699219"
        width="26"
        height="2.69388"
        rx="1.34694"
        transform="rotate(45 1.91992 0.699219)"
        fill={color}
      />
      <rect
        width="26"
        height="2.69388"
        rx="1.34694"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 18.4004 0.699219)"
        fill={color}
      />
    </svg>
  )
}

export default CrossIcon
