import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import type {
  InvisionComUserRes,
  SignUpFormDataType,
  CreateDtlUserResType,
  StrapiUserDataType,
  LoginDataType,
  LoginSuccessType,
  LoginErrorResType,
  UserDetailI,
} from 'src/types'

export const authenticationApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    signup: builder.mutation<InvisionComUserRes, SignUpFormDataType>({
      query: (body) => ({
        url: '/api/create-user',
        method: 'POST',
        body,
      }),
    }),
    createDtlUser: builder.mutation<CreateDtlUserResType, StrapiUserDataType>({
      query: (body) => {
        return {
          url: '/api/dtl-users',
          method: 'POST',
          body,
        }
      },
    }),
    login: builder.mutation<
      LoginSuccessType | LoginErrorResType,
      LoginDataType
    >({
      query: (body) => ({
        url: '/api/login',
        method: 'POST',
        body,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    homeApiCall: builder.query<UserDetailI, { id: number | string }>({
      query: (body) => ({
        url: `/api/dtl-users/${body.id}?populate=*`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useSignupMutation,
  useCreateDtlUserMutation,
  useLoginMutation,
  useHomeApiCallQuery,
} = authenticationApi
