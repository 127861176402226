import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { VolunteerApiResData, VolunteerApiPOSTReqData } from 'src/types'

export const volunteerApi = createApi({
  reducerPath: 'volunteerApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    volunteer: builder.mutation<VolunteerApiResData, VolunteerApiPOSTReqData>({
      query: (body) => ({
        url: '/api/volunteers',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useVolunteerMutation } = volunteerApi
