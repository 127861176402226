import React from 'react'

export default function SmileIcon({
  onClick,
}: {
  onClick?: () => void
}): JSX.Element {
  return (
    <svg
      width="55"
      height="54"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M24.8789 44C35.9246 44 44.8789 35.0457 44.8789 24C44.8789 12.9543 35.9246 4 24.8789 4C13.8332 4 4.87891 12.9543 4.87891 24C4.87891 35.0457 13.8332 44 24.8789 44Z"
        fill="#FFD600"
      />
      <path
        d="M16.8789 28C16.8789 28 19.8789 32 24.8789 32C29.8789 32 32.8789 28 32.8789 28"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8789 18H18.8997"
        stroke="black"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8789 18H30.8997"
        stroke="black"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
