import { createApi } from '@reduxjs/toolkit/query/react'
import { CampaignPartnerI } from 'src/types'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'

export const campaignPartner = createApi({
  reducerPath: 'campaignPartnerApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getCampaignPartnerApi: builder.query<CampaignPartnerI, void>({
      query: () => ({
        url: 'api/campaign-partners?populate=deep',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetCampaignPartnerApiQuery } = campaignPartner
