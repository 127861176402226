import React from 'react'
import SubMenuCard from './subMenuCard'
// import clsx from 'clsx'
import useStyles from './styles'

interface emergencyCard {
  closeSubMenu: () => void
  content: { itemName: string; itemUrl: string }
}
interface donateNowCard {
  closeSubMenu: () => void
  content: { itemName: string; itemUrl: string }
}

const ItsAnEmergencyCard = ({ closeSubMenu, content }: emergencyCard) => {
  const { classes } = useStyles()
  return (
    <SubMenuCard
      text={content.itemName}
      route={content.itemUrl}
      className={classes.endCard}
      closeSubMenu={closeSubMenu}
    />
  )
}

const DonateNowCard = ({ closeSubMenu, content }: donateNowCard) => {
  const { classes } = useStyles()
  return (
    <SubMenuCard
      text={content.itemName}
      route={content.itemUrl}
      className={classes.endCard}
      closeSubMenu={closeSubMenu}
    />
  )
}

export { ItsAnEmergencyCard, DonateNowCard }
