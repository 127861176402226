/* eslint-disable @typescript-eslint/no-explicit-any */
import isEmpty from 'lodash.isempty'
import orderBy from 'lodash.orderby'
import size from 'lodash.size'

import type {
  SearchArticlesRes,
  GetDTLOriginalRes,
  VideoDataType,
  GetAllTopicsType,
  CareersResData,
  FeaturedArticleRes,
} from 'src/types'
import DefaultImage from 'src/assets/images/default.jpg'
import { AlgoliaRecommendedArticles } from 'src/types/store'

export const APP_VERSION = 'v1.0.0'
export const SEARCH_WORD_LENGTH = 2

export function guidGenerator() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  )
}

export function getImageUrl(path: string) {
  return `${path}`
}

export function kFormatter(num: number) {
  if (Math.abs(num) > 999999) {
    return (
      Math.sign(num) * parseFloat((Math.abs(num) / 1000000).toFixed(1)) + 'M'
    )
  }
  if (Math.abs(num) > 999) {
    return Math.sign(num) * parseFloat((Math.abs(num) / 1000).toFixed(1)) + 'k'
  }

  return Math.sign(num) * Math.abs(num)
}

export const checkSingular = (
  singular: string,
  plural: string,
  count: number
): string => {
  if (count > 1) {
    return plural
  }
  return singular
}

export function checkSearchQuery(val: string) {
  // check if empty
  if (isEmpty(val)) return false

  // check if len > 2
  if (!(size(val) > SEARCH_WORD_LENGTH)) return false

  return true
}

export const preprocessArticlesData = (data: any) => {
  if (!data) return []
  const res = data.map((item: any) => {
    return {
      id: item.id,
      img: getImageUrl(item.attributes?.Images?.data[0]?.attributes?.url),
      date: item.attributes?.publishedAt,
      title: item.attributes?.Title,
      tags: item.attributes?.tags?.data?.map((t: { tag: string }) => {
        return {
          content: t.tag,
          href: '#',
        }
      }),
    }
  })
  return res
}

interface YourStoryData {
  id: number
  attributes: {
    createdAt: string
    publishedAt: string
    story: string
    updatedAt: string
  }
}

export const preprocessYourStories = (data: YourStoryData[]) => {
  if (!data) return

  return data.map((item: YourStoryData) => {
    return {
      id: item.id,
      story: item.attributes.story,
    }
  })
}

// TODO: import correct algoliasearch hit type(AlgoliaHit)
export const preprocessSearchArticles = (data: any) => {
  if (!data) return

  const res = data.map((item: SearchArticlesRes) => ({
    id: item.id,
    title: item._highlightResult.title?.value,
    date: item._highlightResult.publishedAt?.value,
    image: `${item._highlightResult.images?.url?.value}`,
    tags: item.tags?.tag
      ? item.tags.tag.map((t: string) => {
          return { content: t, href: t }
        })
      : [],
  }))
  return res
}

export const preprocessDTLOriginalData = (data: GetDTLOriginalRes) => {
  const response = data.data.map((item: VideoDataType) => ({
    img: item.attributes?.thumbnail?.data?.attributes?.url
      ? `${item.attributes.thumbnail.data.attributes.url}`
      : DefaultImage.src,
    videoID: item?.attributes?.videoID ? item.attributes.videoID : '',
    views: item.attributes.viewCount ? parseInt(item.attributes.viewCount) : 0,
    title: item.attributes.title || '',
  }))

  return orderBy(response, ['views'], ['desc'])
}
export const nameInCamelCase = (name: string) => {
  if (!name) return ''
  // Split the name into words
  const words = name.split(' ')

  // Convert the first word to lowercase
  let camelCaseName = words[0].toLowerCase()

  // Loop through the remaining words
  for (let i = 1; i < words.length; i++) {
    // Capitalize the first letter of each word and append it to the camelCaseName
    camelCaseName += words[i][0].toUpperCase() + words[i].slice(1).toLowerCase()
  }

  return camelCaseName
}
export const preprocessTopicsData = (data: GetAllTopicsType[]) => {
  if (!data) return []
  return data
    .filter((item) => item.attributes.mainCategory)
    .map((item) => ({
      content: item.attributes.topic,
      link: item.attributes.topic.toLowerCase().trim(),
    }))
}

export const preprocessHeroHeaderData = (data: any, tagName: string) => {
  if (!data) return
  return {
    id: data.id,
    category: data.category?.topic || 'DTL',
    image: data.image?.url ? data.image.url : '',
    title: data.title && data.title !== 'anything' ? data.title : tagName,
    subTitle: data.subTitle,
    createdAt: data.createdAt,
  }
}

export const preprocessAlgoliaRecommendedArticles = (
  articles: AlgoliaRecommendedArticles
) => {
  const recommendedArticle = articles.data.map((el) => {
    const {
      author,
      comments_count,
      image,
      likes_count,
      metaValue,
      objectID,
      slug,
      tags,
      title,
    } = el.attributes

    const formattedTags = Array.isArray(tags)
      ? tags.map((tag) => tag.tagName)
      : []

    return {
      title,
      author,
      comments_count,
      id: objectID,
      objectID,
      image,
      likes_count,
      metaValue,
      slug,
      tags: formattedTags,
    }
  })

  return recommendedArticle
}

export const preprocessFeaturedArticles = (data: any) => {
  if (!data) return null
  return {
    id: data.data[0].attributes.article.data.id,
    date: data.data[0].attributes.article.data.attributes.publishedAt,
    title: data.data[0].attributes.article.data.attributes.title,
    image:
      data.data[0].attributes.article.data.attributes?.images?.data?.attributes
        .url || '',
    link: data.data[0].attributes.article.data.id,
    isLiked: false,
    slug: data.data[0].attributes.article.data.attributes.slug,
    article_like_count:
      data.data[0].attributes.article.data.attributes.article_like_count,
    // tags: [data.topic.topic],
  }
}

export const preprocessAllFeaturedArticles = (
  data: FeaturedArticleRes,
  query = 'all'
) => {
  if (!data) return null

  let filterQuery = 'all'
  if (query == 'StopAsianHate') filterQuery = `#${query}`
  else filterQuery = query
  const articleData = data.data.filter(
    (item) =>
      item.attributes.topic.data.attributes.topic.toLowerCase() ==
      filterQuery.toLowerCase()
  )

  if (articleData && articleData.length) {
    return {
      id: articleData[0].attributes.article.data.id,
      date: articleData[0].attributes.article.data.attributes.publishedAt,
      title: articleData[0].attributes.article.data.attributes.title,
      image:
        articleData[0].attributes.article.data.attributes.images.data.attributes
          .url || '', // add a default image here
      link: articleData[0].attributes.article.data.attributes.slug,
      tags: articleData[0].attributes.article.data.attributes.tags.data
        ? articleData[0].attributes.article.data.attributes.tags.data.map(
            (item) => item.attributes.topic
          )
        : [articleData[0].attributes.topic.data.attributes.topic],
      isLiked: false,
      article_like_count:
        articleData[0].attributes.article.data.attributes.article_like_count,
    }
  }

  // check for ALL featured article
  const allArticleData = data.data.filter(
    (item) => item.attributes.topic.data.attributes.topic.toLowerCase() == 'all'
  )
  if (allArticleData && allArticleData.length) {
    return {
      id: allArticleData[0].attributes.article.data.id,
      date: allArticleData[0].attributes.article.data.attributes.publishedAt,
      title: allArticleData[0].attributes.article.data.attributes.title,
      image:
        allArticleData[0].attributes.article.data.attributes.images.data
          .attributes.url || '',
      link: allArticleData[0].attributes.article.data.attributes.slug,
      tags: [allArticleData[0].attributes.topic.data.attributes.topic],
      article_like_count:
        allArticleData[0].attributes.article.data.attributes.article_like_count,
    }
  }

  // if search query and neither default ALL category is found then return whatever is available
  const defaultArticle: any = data.data[0]

  if (defaultArticle) {
    return {
      id: defaultArticle.attributes.article.data.id,
      date: defaultArticle.attributes.article.data.attributes.publishedAt,
      title: defaultArticle.attributes.article.data.attributes.title,
      image: defaultArticle.attributes.article.data.attributes.images.data
        .attributes.url
        ? defaultArticle.attributes.article.data.attributes.images.data
            .attributes.url
        : DefaultImage.src,
      link: defaultArticle.attributes.article.data.attributes.slug,
      tags: [defaultArticle.attributes.topic.data.attributes.topic],

      article_like_count:
        defaultArticle.attributes.article.data.attributes.article_like_count,
    }
  }
}

// ? careers data preprocessing
export const preprocessCareerData = (data: CareersResData[]) => {
  if (!data) return

  const location: string[] = []
  const department: string[] = []
  const workingHours: string[] = []

  const jobsListing = data.map((item) => {
    if (item.attributes.location) {
      if (!location.includes(item.attributes.location)) {
        location.push(item.attributes.location)
      }
    }

    if (item.attributes.field) {
      if (!department.includes(item.attributes.field)) {
        department.push(item.attributes.field)
      }
    }

    if (item.attributes.workingHours) {
      if (!workingHours.includes(item.attributes.workingHours)) {
        workingHours.push(item.attributes.workingHours)
      }
    }
    return {
      id: item.id,
      position: {
        field: item.attributes.field,
        designation: item.attributes.position,
      },
      location: item.attributes.location,
      salary: item.attributes.salary,
      hours: item.attributes.workingHours,
    }
  })

  return { jobsListing, location, department, workingHours }
}

export const getSpecificPartOfString = (str: string) => {
  const supportHubIndex = str.toLowerCase().indexOf('support hub')
  if (supportHubIndex === -1) {
    return str
  }
  const specificPart = str.slice(0, supportHubIndex).trim()
  return specificPart
}

export function getGTagCookie(name: string) {
  const cookieString = document.cookie
  const cookies = cookieString.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(name + '=')) {
      return JSON.parse(cookie.substring(name.length + 1))
    }
  }
  return null
}
