import React from 'react'
import { getCookie, hasCookie, setCookie } from 'cookies-next'
import { Snackbar, Grid, Typography, Button } from '@mui/material'
// import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui'
import { Nunito_Sans } from '@next/font/google'
import { Box } from '@mui/system'
import { CrossIcon } from 'src/assets/svgs'
import Popover from '@mui/material/Popover'
import { getGTagCookie } from 'src/utils'

import { Switch } from '@mui/material'
import Script from 'next/script'
const nunito_sans = Nunito_Sans({
  weight: ['400', '700', '800'],
  subsets: ['latin'],
})

const CookieConsent = () => {
  const { classes } = useStyles()
  const [showConsent, setShowConsent] = React.useState(true)
  const [open, setOpen] = React.useState(true)
  const [switchState, setSwitchState] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const lang = getCookie('lang')
  // const [lang, setLang] = React.useState<string>()

  // React.useEffect(() => {
  //   if (
  //     typeof window === 'object' &&
  //     (window.location.href.includes('mx') ||
  //       window.location.href.includes('quit') ||
  //       window.location.href.includes('quiat'))
  //   ) {
  //     localStorage.setItem('lang', 'Es')
  //     setLang('Es')
  //   } else {
  //     localStorage.setItem('lang', 'En')
  //     setLang('En')
  //   }
  // }, [typeof window === 'object' ? window.location.href : null])
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  React.useEffect(() => {
    setShowConsent(hasCookie('localConsent'))
  }, [showConsent])

  React.useEffect(() => {
    getGTagCookie('gTagCookies') === null
      ? setSwitchState(true)
      : setSwitchState(getGTagCookie('gTagCookies'))
  }, [])

  const acceptCookie = () => {
    setShowConsent(true)
    setCookie('localConsent', 'true', {})
  }
  const rejectCookie = () => {
    setShowConsent(false)
    setOpen(false)
    setCookie('localConsent', 'false', {})
    document.cookie = 'gTagCookies' + '=' + 'false' + '; path=/'
  }
  if (showConsent) {
    return null
  }
  const openPop = Boolean(anchorEl)
  const id = openPop ? 'simple-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePop = () => {
    setAnchorEl(null)
  }

  function setGtagCookies(value: boolean) {
    document.cookie = 'gTagCookies' + '=' + value + '; path=/'
    setSwitchState(value)
  }

  return (
    <Box>
      <Snackbar open={open} onClose={handleClose}>
        <Grid
          item
          container
          md={8}
          direction="column-reverse"
          classes={classes}
          className={nunito_sans.className}
        >
          <Grid
            container
            item
            sm={1}
            sx={{ display: { xl: 'none', lg: 'none', md: 'none' } }}
            className={classes.mblCloseIcon}
            onClick={(e) => handleClose(e)}
          >
            <CrossIcon color="black" className={classes.closeIcon}></CrossIcon>
          </Grid>
          <Grid item md={7} classes={classes.textWrapper}>
            <Typography component="p" className={classes.textStyle}>
              {lang === 'En'
                ? `This website uses cookies to improve user experience. By using our
                website you consent to all cookies in accordance with our Cookie
                Policy.`
                : 'Nuestro sitio web utiliza cookies para mejorar la experiencia de usuario. Al utilizar nuestro sitio, usted confirma que acepta todas las cookies de acuerdo con nuestra Política de Cookies.'}
            </Typography>
          </Grid>

          <Grid item md={4} className={classes.acceptBtnWrapper}>
            <Button
              className={classes.chooseBtn}
              onClick={(e) => handleClick(e)}
            >
              {lang === 'En' ? 'Let me Choose' : 'Permíteme elegir'}
            </Button>
            <Button
              className={classes.acceptBtn}
              onClick={() => acceptCookie()}
            >
              {lang === 'En' ? 'Accept' : 'Acceptar'}
            </Button>

            <Popover
              id={id}
              open={openPop}
              anchorEl={anchorEl}
              onClose={handleClosePop}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              sx={{
                zIndex: 9999999,
                borderRadius: '24px !important',
                marginBottom: '2rem',
              }}
              className={classes.popOverWrapper}
            >
              <div className={classes.popOver}>
                <Typography className={classes.popOverHeading}>
                  {lang === 'En'
                    ? 'Ditch the Label'
                    : ' Quítate las etiquetas '}
                </Typography>

                <Typography className={classes.subHeading}>
                  {' '}
                  {lang === 'En'
                    ? `COOKIE PREFERENCES`
                    : `Preferencias de Cookie`}
                </Typography>

                <Typography className={classes.desc}>
                  {lang === 'En'
                    ? `Cookies are data files which can hold small amounts of info
                    and they are stored on your device (computer, smartphone, etc)
                    when you first visit a website. We use cookies when you visit
                    our site, but you can choose to not allow some types of
                    cookies.`
                    : `Las cookies son pequeños archivos de texto que son descargados en su computadora o dispositivo móvil cuando visita un sitio web. Utilizamos cookies cuando visita nuestro sitio, pero puede elegir no permitir algunos tipos de cookies`}
                </Typography>

                <Button
                  className={classes.allowAllBtn}
                  onClick={() => {
                    setGtagCookies(true)
                    acceptCookie()
                  }}
                >
                  {lang === 'En' ? `ALLOW ALL` : 'Permitir todas'}
                </Button>

                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.subHeading}>
                    {' '}
                    {lang === 'En'
                      ? `NECESSARY COOKIES`
                      : `Cookies necesarias`}{' '}
                  </Typography>
                  <Typography
                    className={classes.subHeading}
                    sx={{
                      color: 'green !important',
                      textAlign: 'end  !important',
                    }}
                  >
                    {' '}
                    {lang === 'En' ? 'ALWAYS ON' : 'Siempre activas'}
                  </Typography>
                </Box>
                <Typography className={classes.desc}>
                  {lang === 'En'
                    ? ` These cookies are essential for this site to work properly and
                    they allow you to navigate the site and use our features.
                    These cookies improve functionality and user experience.`
                    : `Estas cookies son absolutamente necesarias para el funcionamiento de nuestro sitio y le permiten navegar el sitio y utilizar nuestras funciones.`}
                </Typography>

                <Box display="flex" justifyContent="space-between" pt={0.7}>
                  <Typography className={classes.subHeading}>
                    {' '}
                    {lang === 'En'
                      ? `SITE ANALYTICS COOKIES`
                      : `Cookies de comportamiento/estadísticas`}
                  </Typography>
                  <Switch
                    checked={switchState}
                    onClick={() => setGtagCookies(!switchState)}
                  />
                </Box>

                <Typography className={classes.desc}>
                  {lang === 'En'
                    ? `These cookies allow us to measure and analyse how our user
                    engage with the site, to improve both its functionality and
                    your experience.`
                    : `Estas cookies nos permiten medir y analizar cómo interactúan nuestros usuarios con el sitio para mejorar la funcionalidad y su experiencia. `}
                </Typography>
                <Button
                  className={`${classes.allowAllBtn} ${classes.rejectAllBtn}`}
                  onClick={() => {
                    setGtagCookies(false)
                    rejectCookie()
                  }}
                >
                  {lang === 'En' ? `Reject All` : `Declinar todas`}{' '}
                </Button>
              </div>
            </Popover>
          </Grid>
          <Grid
            container
            item
            md={0.6}
            sx={{ display: { md: 'flex', xs: 'none', sm: 'none' } }}
            onClick={(e) => handleClose(e)}
          >
            <CrossIcon color="black" className={classes.closeIcon}></CrossIcon>
          </Grid>
        </Grid>
      </Snackbar>
      {/* {switchState ? (
        <div>
          <Script src="https://www.googletagmanager.com/gtag/js?id=G-F5CWCGS4JT" />
          <Script id="google-analytics">
            {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'G-F5CWCGS4JT');
`}
          </Script>
        </div>
      ) : (
        ''
      )} */}
      {switchState && getCookie('lang') === 'En' ? (
        <div>
          <Script src="https://www.googletagmanager.com/gtag/js?id=G-F5CWCGS4JT" />
          <Script id="google-analytics">
            {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'G-F5CWCGS4JT');
`}
          </Script>
        </div>
      ) : switchState ? (
        <div>
          <Script src="https://www.googletagmanager.com/gtag/js?id=G-RJ0WEGMF8H" />
          <Script id="google-analytics">
            {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'G-RJ0WEGMF8H');
`}
          </Script>
        </div>
      ) : (
        ''
      )}
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    backdropFilter: 'blur(47.5px)',
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '22px',
  },
  acceptBtn: {
    borderRadius: '30.5px',
    height: 'auto',
    border: '1.3px solid #000',
    textTransform: 'uppercase',
    padding: '2px 18px',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'white',
      color: 'black',
      borderColor: 'black',
    },
  },
  allowAllBtn: {
    borderRadius: '30.5px',
    height: 'auto',
    border: '1.3px solid #000',
    textTransform: 'uppercase',
    padding: '2px 18px',
    background: 'black',
    color: 'white',
    margin: '1rem 0 .56rem',
    width: '100%',
    fontSize: '14px',
    '&:hover': {
      background: 'white',
      color: 'black',
      borderColor: 'black',
    },
  },
  chooseBtn: {
    borderRadius: '30.5px',
    height: 'auto',
    border: '1.3px solid #000',
    textTransform: 'uppercase',
    padding: '2px 18px',
    marginRight: '1rem',
  },
  acceptBtnWrapper: {
    alignSelf: 'center',
    margin: '0 auto',
    justifyContent: 'center',
    display: 'flex',
    padding: '18px',
  },
  closeIcon: {
    fontSize: '15px',
    margin: '7px',
    cursor: 'pointer',
    marginTop: '9px',
    width: '16px',
  },
  textStyle: {
    padding: '10px',
    margin: '10px',
  },
  textWrapper: {},
  mblCloseIcon: {
    justifyContent: 'end',
    margin: '7px',
  },
  popOver: {
    padding: '1.1rem 1rem !important',
    maxWidth: '300px',
  },
  popOverWrapper: {
    '.MuiPaper-root': {
      borderRadius: '20px !important',
    },
  },
  popOverHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: ' normal',
    fontWeight: '900',
    fontSize: '16px',
    textTransform: 'uppercase',
    color: '#000000',
    textAlign: 'start',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      width: '100%',
      lineHeight: 'normal',
    },
  },
  subHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: ' normal',
    fontWeight: '900',
    fontSize: '13px',
    textTransform: 'uppercase',
    color: '#000000',
    textAlign: 'start',
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      width: '100%',
      lineHeight: 'normal',
    },
  },

  desc: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: ' normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#000000',
    textAlign: 'start',
    opacity: '0.7',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: 'normal',
    },
  },

  rejectAllBtn: {
    background: 'white',
    color: 'black',
    marginBottom: '0',
    '&:hover': {
      background: 'black',
      color: 'white',
      borderColor: 'black',
    },
  },
}))

export default CookieConsent
