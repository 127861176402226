import React from 'react'

export default function SearchIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00692 3.00331C7.41439 3.00331 5.8871 3.63591 4.76101 4.76194C3.63493 5.88797 3.00231 7.41519 3.00231 9.00764C3.00231 10.6001 3.63493 12.1273 4.76101 13.2533C5.8871 14.3794 7.41439 15.012 9.00692 15.012C10.5994 15.012 12.1267 14.3794 13.2528 13.2533C14.3789 12.1273 15.0115 10.6001 15.0115 9.00764C15.0115 7.41519 14.3789 5.88797 13.2528 4.76194C12.1267 3.63591 10.5994 3.00331 9.00692 3.00331ZM1.70323e-07 9.00764C-0.000180723 7.59017 0.33422 6.19268 0.976007 4.92881C1.61779 3.66495 2.54884 2.5704 3.69343 1.73419C4.83802 0.897976 6.16383 0.343715 7.56303 0.116483C8.96223 -0.110749 10.3953 -0.00453699 11.7457 0.426482C13.0961 0.857501 14.3257 1.60116 15.3345 2.59696C16.3433 3.59277 17.1028 4.81261 17.5513 6.15728C17.9997 7.50195 18.1244 8.93347 17.9152 10.3354C17.7061 11.7374 17.169 13.0702 16.3476 14.2254L23.5786 21.4561C23.8521 21.7392 24.0034 22.1184 23.9999 22.512C23.9965 22.9055 23.8386 23.282 23.5603 23.5603C23.282 23.8387 22.9055 23.9965 22.5119 23.9999C22.1183 24.0034 21.7391 23.8521 21.456 23.5786L14.2264 16.3494C12.8789 17.3075 11.2937 17.8763 9.64442 17.9933C7.99515 18.1103 6.34548 17.7711 4.87619 17.0129C3.40691 16.2546 2.1747 15.1066 1.31459 13.6945C0.454489 12.2825 -0.000321323 10.661 1.70323e-07 9.00764Z"
        fill="black"
        fillOpacity=".65"
      />
    </svg>
  )
}
