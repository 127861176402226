/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { getCookie } from 'cookies-next'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { HubHeroHeaderRes, GetPageRes } from 'src/types'

export const PagesApi = createApi({
  reducerPath: 'pagesApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    getPageByName: builder.query<GetPageRes, string | string[] | undefined>({
      query: (pageName) => ({
        url:
          getCookie('lang') === 'En'
            ? `/api/article-schemas?filters[name][$eq]=${pageName}&populate=deep`
            : `/api/article-schemas?filters[name][$eq]=${
                'mx-' + pageName
              }&populate=deep&locale=es-MX`,
        method: 'GET',
      }),
    }),
    getHeroHeader: builder.query<HubHeroHeaderRes, string | number>({
      query: (category) => ({
        url:
          getCookie('lang') === 'En'
            ? `/api/hub-hero-header?q=${category}`
            : `/api/hub-hero-header?q=${category}&locale=es-MX`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetPageByNameQuery, useGetHeroHeaderQuery } = PagesApi
