import * as React from 'react'
import Image from 'next/image'
import Box from '@mui/material/Box'
import {
  Button,
  Divider,
  Grid,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import FBLogo from 'src/assets/svgs/footer/Facebook.svg'
import TwtLogo from 'src/assets/svgs/footer/Twitter.svg'
import Instagram from 'src/assets/images/Instagram.svg'
import ytLogo from 'src/assets/images/youtube.png'
import Tiktok from 'src/assets/images/Tiktok.svg'

// import AsosLogo from 'src/assets/svgs/footer/asos_logo.svg'
import useStyles from './styles'
import Link from 'next/link'
import { Lato, Nunito_Sans } from '@next/font/google'
import clsx from 'clsx'
import lottieJson from 'src/assets/lottieFiles/emoji clutter EN updated'
import { ButtonComponent, RoundTextField } from 'src/components/shared/atoms'
import { useInView } from 'react-intersection-observer'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/store'
import { GetLayoutRes } from 'src/types/store'
import RoundSelect from 'src/components/shared/atoms/roundSelect/RoundSelect'
import { getCookie } from 'cookies-next'
import Lottie from 'react-lottie-light'

const nunito = Nunito_Sans({
  style: 'normal',
  weight: '700',
  subsets: ['latin'],
})
const lato = Lato({ style: 'normal', weight: '400', subsets: ['latin'] })

interface IProps {
  content: GetLayoutRes
}
const Footer: React.FC<IProps> = ({ content }) => {
  const { classes } = useStyles()
  const zenMode = useSelector((state: RootState) => state.zenModeState.zenMode)
  const isBrowser = React.useState(typeof window === 'object' ? true : false)
  const [width, setWidth] = React.useState(
    typeof window === 'object' ? window?.innerWidth : 0
  )

  const [signupEmailValue, setSignupEmailValue] = React.useState('')
  const router = useRouter()

  const [lang] = React.useState(getCookie('lang'))

  const newTargetMx = () => {
    if (typeof window === 'object') {
      const newWindow = window.open(
        'http://qle.mx/',
        '_blank',
        'noopener,noreferrer'
      )
      if (newWindow) newWindow.opener = null
    }
  }

  const newTargetEng = () => {
    if (typeof window === 'object') {
      const newWindow = window.open(
        'https://ditchthelabel.org/',
        '_blank',
        'noopener,noreferrer'
      )

      if (newWindow) newWindow.opener = null
    }
  }
  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === 'Es') {
      newTargetMx()
    } else {
      newTargetEng()
    }
  }
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window?.innerWidth)
    }

    if (window) {
      window.addEventListener('resize', handleResize)
      setWidth(window?.innerWidth)
    }

    return () => {
      if (window) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  const animationData = lottieJson(isBrowser && lang === 'En')

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
  }
  const { ref, inView } = useInView()
  return (
    <div className={classes.wrapper}>
      <Box component="footer" className={classes.root}>
        <Grid
          container
          p={8}
          className={
            zenMode
              ? clsx(classes.innerContainer, classes.zenModeRootContainer)
              : classes.innerContainer
          }
          pt={13}
          direction="row"
        >
          <Grid container item xs={6} md={3} display="flex" direction="row">
            <Grid item xs={12}>
              <h3 className={clsx(nunito.className, classes.heading)}>
                {content.data.attributes.footerOrganization.heading}
              </h3>
            </Grid>
            {content.data.attributes.footerOrganization.menuLinks.map(
              (curr, i) => (
                <Grid key={i} item xs={12}>
                  <Link
                    href={curr.itemUrl ? curr.itemUrl : '#'}
                    className={clsx(lato.className, classes.links)}
                  >
                    {curr.itemName}
                  </Link>
                </Grid>
              )
            )}
          </Grid>
          <Grid item xs={6} md={3} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <h3 className={clsx(nunito.className, classes.heading)}>
                {content.data.attributes.footerINeedHelpLink.heading}
              </h3>
            </Grid>
            {content.data.attributes.footerINeedHelpLink.menuItems.map(
              (curr, i) => (
                <Grid key={i} item xs={12}>
                  <Link
                    href={curr.itemUrl ? curr.itemUrl : '#'}
                    className={clsx(lato.className, classes.links)}
                  >
                    {curr.itemName}
                  </Link>
                </Grid>
              )
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            <Grid item xs={12}>
              <h3 className={clsx(nunito.className, classes.heading)}>
                {content.data.attributes.footerWaysToHelpLinks.heading}
              </h3>
            </Grid>
            {content.data.attributes.footerWaysToHelpLinks.menuItems.map(
              (curr, i) => (
                <Grid key={i} item xs={12}>
                  <Link
                    href={curr.itemUrl ? curr.itemUrl : '#'}
                    className={clsx(lato.className, classes.links)}
                  >
                    {curr.itemName}
                  </Link>
                </Grid>
              )
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={3}
            rowGap={5}
            sx={{ display: { lg: 'flex', md: 'flex', xs: 'none', sm: 'none' } }}
          >
            <Grid container item xs={12} textAlign="center">
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  component="h5"
                  className={clsx(nunito.className, classes.signUpheading)}
                >
                  {lang == 'En' ? 'Site Language' : 'Idioma del sitio'}
                </Typography>
                <RoundSelect
                  value={lang}
                  zenMode={zenMode}
                  content={[
                    {
                      value: 'En',
                      content: 'English',
                      disabled: false,
                    },
                    {
                      value: 'es-MX',
                      content: 'Spanish',
                      disabled: false,
                    },
                  ]}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Typography
                component="h5"
                className={clsx(nunito.className, classes.signUpheading)}
              >
                {content.data.attributes.signUpSection.heading}
              </Typography>
            </Grid>

            <RoundTextField
              variant="outlined"
              fullWidth
              onChange={(e) => setSignupEmailValue(e.target.value)}
              className={classes.signupField}
              placeholder={content.data.attributes.signUpSection.placeholder}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={() =>
                      router.push(
                        `${
                          content.data.attributes.signUpSection.button[0].href
                            ? content.data.attributes.signUpSection.button[0]
                                .href
                            : '/auth/signup'
                        }?email=${signupEmailValue}`
                      )
                    }
                    className={clsx(nunito.className, classes.signUpButton)}
                  >
                    {content.data.attributes.signUpSection.button[0].content}{' '}
                  </Button>
                ),
              }}
            />
          </Grid>
          <Grid
            container
            item
            md={12}
            sx={{ display: { lg: 'flex', md: 'flex', xs: 'none' } }}
            className={classes.borderLine}
          >
            <Divider orientation="horizontal" variant="middle" />
          </Grid>
          <Grid alignItems="center" container item xs={6} md={8}>
            <Grid
              item
              xs={12}
              md={2}
              xl={width > 1250 ? 2 : 2}
              lg={2}
              className={classes.icons}
            >
              <p
                className={clsx(nunito.className, classes.headingIcons)}
                style={{ marginRight: '10px' }}
              >
                {content.data.attributes.powerby.heading}
              </p>
            </Grid>
            <Grid
              md={7}
              item
              lg={7}
              xl={7}
              sm={1}
              sx={{
                display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                flex: 1,
                overflow: 'hidden',
              }}
            >
              {isBrowser &&
              content?.data?.attributes?.footerPartnerLogos?.length > 3 ? (
                <Grid className={classes.brandsLogoContainer}>
                  {content?.data?.attributes?.footerPartnerLogos?.map((el) => (
                    <Grid
                      key={el.id}
                      style={{
                        position: 'relative',
                        width: '60px',
                        height: '60px',
                      }}
                    >
                      <Image
                        src={el?.partnerLogo?.data?.attributes?.url || ''}
                        alt={el?.partnerLogo?.data?.attributes?.name || ''}
                        fill
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid className={classes.mxbrandsLogoContainer}>
                  {content?.data?.attributes?.footerPartnerLogos?.map((el) => (
                    <Grid
                      key={el.id}
                      style={{
                        position: 'relative',
                        width: '60px',
                        height: '60px',
                      }}
                    >
                      <Image
                        src={el?.partnerLogo?.data?.attributes?.url || ''}
                        alt={el?.partnerLogo?.data?.attributes?.name || ''}
                        fill
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid
              sx={{
                display: { lg: 'none', md: 'none', sm: 'grid', xs: 'grid' },
                gap: '40px',
                gridTemplateColumns: 'auto auto',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {content?.data?.attributes?.footerPartnerLogos?.length
                ? content.data.attributes.footerPartnerLogos.map((el) => (
                    <Grid
                      key={el.id}
                      style={{ position: 'relative', width: 50, height: 50 }}
                    >
                      <Image
                        src={el?.partnerLogo?.data?.attributes?.url}
                        alt={el?.partnerLogo?.data?.attributes?.name}
                        fill
                      />
                    </Grid>
                  ))
                : ''}
            </Grid>

            <Grid
              item
              xs={6}
              md={2}
              xl={2}
              lg={2}
              className={classes.icons}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                className={clsx(nunito.className, classes.learnMore)}
                sx={{ display: { lg: 'flex', md: 'flex', xs: 'none' } }}
                onClick={() => {
                  router.push(
                    content.data.attributes.powerby.button.href
                      ? content.data.attributes.powerby.button.href
                      : '#'
                  )
                }}
              >
                {' '}
                {content.data.attributes.powerby.button.content}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Divider
              className={classes.borderLine}
              sx={{
                display: {
                  lg: 'flex',
                  md: 'flex',
                  xs: 'none',
                },
              }}
              orientation={'vertical'}
              flexItem
            />
            <Grid item xs={12} md={4} lg={width > 1250 ? 4 : 3}>
              <h3 className={clsx(nunito.className, classes.headingIcons)}>
                {content.data.attributes.footerFollowus}
              </h3>
            </Grid>
            <Grid
              container
              item
              xs={10}
              md={7}
              sm={4}
              lg={7}
              className={classes.icons}
            >
              <Grid item xs={2} md={2} alignSelf="center" ml={0.6}>
                <Link
                  href={
                    isBrowser && lang === 'En'
                      ? 'https://www.facebook.com/DitchtheLabel/'
                      : 'https://www.facebook.com/QuitateLasEtiquetas'
                  }
                >
                  <Image src={FBLogo} alt={'image'} />
                </Link>
              </Grid>
              <Grid item xs={2} md={2} alignSelf="center" ml={0.6}>
                <Link
                  href={
                    isBrowser && lang === 'En'
                      ? 'https://www.youtube.com/@DitchtheLabelORG'
                      : 'https://www.youtube.com/@QuitatelasEtiquetas/videos'
                  }
                >
                  <Image
                    style={{
                      width: '100%',
                      height: '100%',
                      margin: '0px',
                      alignSelf: 'flex-start',
                    }}
                    src={ytLogo}
                    alt={'image'}
                  />
                </Link>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                alignSelf="center"
                ml={0.6}
                className={classes.tiktok}
              >
                <Link
                  href={
                    isBrowser && lang === 'En'
                      ? 'https://www.instagram.com/ditchthelabel/?hl=en'
                      : 'https://www.instagram.com/quitatelasetiquetas/'
                  }
                >
                  <Image src={Instagram} alt={'image'} />
                </Link>
              </Grid>

              <Grid item xs={2} md={2} alignSelf="center" ml={0.6}>
                <Link
                  href={
                    isBrowser && lang === 'En'
                      ? 'https://twitter.com/ditchthelabel?lang=en'
                      : 'https://twitter.com/QLEorg'
                  }
                >
                  <Image src={TwtLogo} alt={'image'} />
                </Link>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                alignSelf="center"
                ml={0.6}
                className={classes.tiktok}
              >
                <Link
                  href={
                    isBrowser && lang === 'En'
                      ? 'https://www.tiktok.com/@ditchthelabel?lang=en'
                      : 'https://www.tiktok.com/@quitatelasetiquetas'
                  }
                >
                  <Image src={Tiktok} alt={'image'} />
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            rowGap={0.5}
            item
            md={12}
            sx={{ display: { lg: 'flex', md: 'flex', xs: 'none' } }}
            alignSelf="flex-start"
            className={classes.borderLine}
          >
            <Divider
              className={classes.borderLine}
              sx={{
                display: { lg: 'flex', md: 'flex', xs: 'none' },
              }}
              orientation="horizontal"
              variant="fullWidth"
            />
          </Grid>
          <Grid
            container
            item
            sx={{ display: { md: 'none' } }}
            xs={12}
            md={0}
            rowSpacing={2}
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <Divider
                className={classes.borderLine}
                orientation="horizontal"
                variant="middle"
              />
            </Grid>
            {content.data.attributes.footerGeneralLink.map((curr, i) => (
              <Grid
                key={i}
                item
                className={clsx(lato.className, classes.policyLinks)}
              >
                <Link
                  href={curr.linkUrl ? curr.linkUrl : '#'}
                  className={clsx(lato.className, classes.policyLinks)}
                >
                  {curr.linkName}
                </Link>
              </Grid>
            ))}

            <Grid item xs={12}>
              <Divider
                className={classes.borderLine}
                orientation="horizontal"
                variant="middle"
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={3}
            rowGap={5}
            sx={{ display: { md: 'none' } }}
          >
            <Grid container item xs={12} textAlign="center">
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: '10px',
                }}
              >
                <Typography
                  component="h5"
                  className={clsx(nunito.className, classes.signUpheading)}
                >
                  {lang == 'En' ? 'Site Language' : 'Idioma del sitio'}
                </Typography>
                <RoundSelect
                  value={lang}
                  zenMode={zenMode}
                  content={[
                    {
                      value: 'En',
                      content: 'English',
                      disabled: false,
                    },
                    {
                      value: 'es-MX',
                      content: 'Spanish',
                      disabled: false,
                    },
                  ]}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} textAlign="center">
              <Typography
                component="h5"
                className={clsx(nunito.className, classes.signUpheading)}
              >
                {content.data.attributes.signUpSection.heading}
              </Typography>
            </Grid>
            <RoundTextField
              variant="outlined"
              fullWidth
              className={classes.signupField}
              onChange={(e) => setSignupEmailValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={() =>
                      router.push(
                        `${
                          content.data.attributes.signUpSection.button[0].href
                            ? content.data.attributes.signUpSection.button[0]
                                .href
                            : '#'
                        }?email=${signupEmailValue}`
                      )
                    }
                    className={clsx(nunito.className, classes.signUpButton)}
                  >
                    {' '}
                    {
                      content.data.attributes.signUpSection.button[0].content
                    }{' '}
                  </Button>
                ),
              }}
            />
          </Grid>
          <Grid item container xs={12} md={6} rowGap={3} textAlign="center">
            <Grid item xs={12} textAlign="center">
              <Typography
                component="h2"
                className={clsx(nunito.className, classes.safeSpaceHeading)}
              >
                {content.data.attributes.joinTheInternetSafeSpace.heading}
              </Typography>
            </Grid>
            <Grid item xs={2} textAlign="center"></Grid>
            <Grid item xs={12} textAlign="center">
              <Typography
                className={clsx(lato.className, classes.safeSpaceText)}
              >
                {' '}
                {
                  content.data.attributes.joinTheInternetSafeSpace.description
                }{' '}
              </Typography>
            </Grid>

            {/* ui fix */}
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              textAlign={{ sm: 'center', md: 'start' }}
            >
              <ButtonComponent
                type="contained"
                text={
                  content.data.attributes.joinTheInternetSafeSpace.button[0]
                    .content
                }
                onClick={() => {
                  document.location.href = content.data.attributes
                    .joinTheInternetSafeSpace.button[0].href
                    ? content.data.attributes.joinTheInternetSafeSpace.button[0]
                        .href
                    : '/'
                }}
                className={clsx(lato.className, classes.joinNowButton)}
                wrapperClass={classes.wrapperClass}
                gradient
              />
            </Grid>
          </Grid>
          {/* lottie animation container */}

          <Grid
            item
            container
            sm={12}
            xs={12}
            md={6}
            textAlign="center"
            sx={{
              display: { xs: 'flex', sm: 'flex', md: 'flex' },
              justifyContent: 'center',
              marginTop: { xs: '-40px', sm: '-30px', md: '0' },
              position: 'relative',
            }}
            ref={ref}
          >
            {inView && (
              <Box
                sx={{
                  position: { xs: 'static', sm: 'static', md: 'absolute' },
                  bottom: '-40px',
                }}
              >
                <Lottie options={defaultOptions}></Lottie>
              </Box>
            )}
          </Grid>

          {/* code for later use */}
        </Grid>

        <Grid
          container
          item
          xs={12}
          px={{ lg: 4, md: 4, sm: 12, xs: 4 }}
          py={isBrowser && lang === 'Es' ? 2 : 0}
          justifyContent={isBrowser && lang === 'Es' ? 'center' : 'start'}
        >
          <Grid item xs={isBrowser && lang === 'Es' ? 12 : 6} md={5}>
            <Typography
              component="p"
              className={clsx(lato.className, classes.footerFont)}
            >
              {
                content.data.attributes.footerCopyRightSection.registrationInfo
                  .registeredNo
              }
            </Typography>
            <Typography
              component="p"
              className={clsx(lato.className, classes.footerFont)}
            >
              {
                content.data.attributes.footerCopyRightSection.registrationInfo
                  .registeredNo2
              }
            </Typography>{' '}
          </Grid>

          <Grid
            item
            xs={6}
            md={isBrowser && lang === 'Es' ? 2 : 4}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {' '}
            <Typography
              component="p"
              className={clsx(lato.className, classes.footerFont)}
            >
              {content.data.attributes.footerCopyRightSection.copyRightText}
            </Typography>{' '}
          </Grid>
          <Grid
            item
            xs={4}
            md={isBrowser && lang === 'Es' ? 5 : 3}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {' '}
            {content.data.attributes.footerGeneralLink.map((curr, i) => (
              <Grid
                key={i}
                item
                className={clsx(lato.className, classes.policyLinksBottom)}
              >
                <Link
                  href={curr.linkUrl ? curr.linkUrl : '#'}
                  className={clsx(lato.className, classes.policyLinksBottom)}
                >
                  {curr.linkName}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default Footer
