import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  selectedDropDownValue: 'all',
}

const selectedDropDownValueSlice = createSlice({
  initialState,
  name: 'selectedDropDownValue-Slice',
  reducers: {
    selectedDropDownValueHandler: (state, value) => {
      return {
        ...state,
        selectedDropDownValue: value.payload,
      }
    },
  },
})

export const { selectedDropDownValueHandler } =
  selectedDropDownValueSlice.actions

export default selectedDropDownValueSlice.reducer
