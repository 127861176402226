import React from 'react'
import { makeStyles } from 'tss-react/mui'

const EyeClosedIcon: React.FC = () => {
  const { classes } = useStyles()
  return (
    <svg
      version="1.1"
      id="Layer_1"
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      width="32px"
      height="32px"
    >
      <path
        d="M16,12c-2.2,0-4,1.8-4,4c0,0.5,0.1,1,0.3,1.5l4.3-5.4C16.4,12,16.2,12,16,12z M19.7,14.5l-4.3,5.4c0.2,0,0.4,0.1,0.6,0.1
      c2.2,0,4-1.8,4-4C20,15.5,19.9,15,19.7,14.5z M16,12c-2.2,0-4,1.8-4,4c0,0.5,0.1,1,0.3,1.5l4.3-5.4C16.4,12,16.2,12,16,12z
       M19.7,14.5l-4.3,5.4c0.2,0,0.4,0.1,0.6,0.1c2.2,0,4-1.8,4-4C20,15.5,19.9,15,19.7,14.5z M16,12c-2.2,0-4,1.8-4,4
      c0,0.5,0.1,1,0.3,1.5l4.3-5.4C16.4,12,16.2,12,16,12z M19.7,14.5l-4.3,5.4c0.2,0,0.4,0.1,0.6,0.1c2.2,0,4-1.8,4-4
      C20,15.5,19.9,15,19.7,14.5z M19.7,14.5l-4.3,5.4c0.2,0,0.4,0.1,0.6,0.1c2.2,0,4-1.8,4-4C20,15.5,19.9,15,19.7,14.5z M19.7,14.5
      l-4.3,5.4c0.2,0,0.4,0.1,0.6,0.1c2.2,0,4-1.8,4-4C20,15.5,19.9,15,19.7,14.5z M24.5,8.5l-3,3.8c0.7,1.1,1.2,2.4,1.2,3.7
      c0,3.7-3,6.7-6.7,6.7c-0.9,0-1.7-0.2-2.4-0.5l-2.4,3c1.5,0.5,3.1,0.8,4.8,0.8c6.7,0,12.4-4.2,14.7-10C29.4,12.9,27.2,10.3,24.5,8.5z
       M19.7,14.5l-4.3,5.4c0.2,0,0.4,0.1,0.6,0.1c2.2,0,4-1.8,4-4C20,15.5,19.9,15,19.7,14.5z M19.7,14.5l-4.3,5.4c0.2,0,0.4,0.1,0.6,0.1
      c2.2,0,4-1.8,4-4C20,15.5,19.9,15,19.7,14.5z M16,6C9.3,6,3.6,10.2,1.3,16c1.2,3.1,3.4,5.7,6.2,7.5l3-3.8c-0.7-1.1-1.2-2.3-1.2-3.7
      c0-3.7,3-6.7,6.7-6.7c0.9,0,1.7,0.2,2.4,0.5l2.4-3C19.3,6.3,17.7,6,16,6z M19.7,14.5l-4.3,5.4c0.2,0,0.4,0.1,0.6,0.1
      c2.2,0,4-1.8,4-4C20,15.5,19.9,15,19.7,14.5z M16,12c-2.2,0-4,1.8-4,4c0,0.5,0.1,1,0.3,1.5l4.3-5.4C16.4,12,16.2,12,16,12z
       M19.7,14.5l-4.3,5.4c0.2,0,0.4,0.1,0.6,0.1c2.2,0,4-1.8,4-4C20,15.5,19.9,15,19.7,14.5z M16,12c-2.2,0-4,1.8-4,4
      c0,0.5,0.1,1,0.3,1.5l4.3-5.4C16.4,12,16.2,12,16,12z M19.7,14.5l-4.3,5.4c0.2,0,0.4,0.1,0.6,0.1c2.2,0,4-1.8,4-4
      C20,15.5,19.9,15,19.7,14.5z"
      />
      <line className={classes.root} x1="24.8" y1="5" x2="7.2" y2="27" />
    </svg>
  )
}

const useStyles = makeStyles()(() => ({
  root: {
    fill: 'none',
    stroke: '#000000',
    strokeWidth: '1.5',
    strokeLinecap: 'round',
    strokeMiterlimit: 10,
  },
}))

export default EyeClosedIcon
