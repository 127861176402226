/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'

export const HomeCardApi = createApi({
  reducerPath: 'homeCardApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types

    getArticleCount: builder.query<
      { id: number; topic: string; article_count: string }[],
      { title: string }
    >({
      query: (body) => ({
        url: `/api/getArticlesCountByTopic/${body.title}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetArticleCountQuery } = HomeCardApi
