import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import useStyles from './Style'
import { Box } from '@mui/material'
import DownArrowIcon from 'src/assets/svgs/icons/downArrow'
import { RoundSelectProps } from 'src/types/components/shared/atom'

const RoundSelect: React.FC<RoundSelectProps> = ({
  zenMode,
  content,
  value,
  onChange,
}) => {
  const { classes } = useStyles()

  return (
    <div>
      <FormControl
        className={`${classes.menuDropdown} ${
          zenMode && classes.menuDropdownZenMode
        }`}
        sx={{ m: 1, minWidth: 120 }}
      >
        <Select
          value={value == -1 ? 'none' : value}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {content
            ? content.length &&
              content.map((el, index) => (
                <MenuItem key={index} value={el.value} disabled={el.disabled}>
                  {' '}
                  {el.content}
                </MenuItem>
              ))
            : ''}
        </Select>
        <Box className={classes.menuIconContainer}>
          <DownArrowIcon />
        </Box>
      </FormControl>
    </div>
  )
}

export default RoundSelect
