/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { GetAllSensitiveWordsRes } from 'src/types'

export const sensitiveWordAPI = createApi({
  reducerPath: 'sensitiveWordAPI',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    // TODO: update types
    sensitiveWords: builder.query<GetAllSensitiveWordsRes, void>({
      query: () => ({
        url: '/api/sensitive-words',
        method: 'get',
      }),
    }),
  }),
})

export const { useSensitiveWordsQuery } = sensitiveWordAPI
