import React from 'react'

const VolumeIcon: React.FC = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="10.9031"
        width="13.5758"
        height="10.9031"
        rx="3"
        fill="#D9D9D9"
      />
      <path
        d="M3.02277 17.7179C1.69144 16.9465 1.69484 15.0228 3.02889 14.2543L21.0081 3.89721C22.3428 3.12834 24.0082 4.09235 24.0055 5.63219L23.9688 26.3849C23.9661 27.9248 22.2973 28.8852 20.9653 28.1135L3.02277 17.7179Z"
        fill="#D9D9D9"
      />
    </svg>
  )
}

const VolumeMuteIcon: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="10.9027"
        width="13.5758"
        height="10.9027"
        rx="3"
        fill="#D9D9D9"
      />
      <path
        d="M3.02213 17.7174C1.69073 16.9461 1.69403 15.0223 3.02807 14.2539L21.0066 3.89777C22.3413 3.12897 24.0067 4.09304 24.0041 5.63287L23.9685 26.3848C23.9659 27.9246 22.2971 28.885 20.9651 28.1133L3.02213 17.7174Z"
        fill="#D9D9D9"
      />
      <path
        d="M5.90903 3.29833L7.72558 2.41919L28.7297 30.4113L26.9132 31.2904L5.90903 3.29833Z"
        fill="#DBD4D4"
        stroke="#1C1B1B"
        strokeWidth="0.75"
      />
    </svg>
  )
}

export { VolumeIcon, VolumeMuteIcon }
