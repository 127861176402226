import React from 'react'
import Avatar from '@mui/material/Avatar'
import { makeStyles } from 'tss-react/mui'
// import { ButtonComponentProps } from 'src/types'

interface AvatarPropsType {
  alt?: string
  url?: string
}

const AvatarComp: React.FC<AvatarPropsType> = ({
  alt,
  url,
}: AvatarPropsType) => {
  const { classes } = useStyles()
  return (
    <Avatar
      alt={alt}
      src={url ? url : 'default url here'}
      className={classes.root}
    />
  )
}

const useStyles = makeStyles()(() => ({
  root: {
    width: 'auto',
  },
}))

export default AvatarComp
