/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { getCookie } from 'cookies-next'
import { baseQueryWithAuth } from 'src/store/ApiCall/baseQuery'
import { GetAllAuthorsRes } from 'src/types/store'

export const authorApi = createApi({
  reducerPath: 'authorApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getAllAuthors: builder.query<GetAllAuthorsRes, void>({
      query: () => ({
        url:
          getCookie('lang') === 'En'
            ? '/api/authers?populate=*'
            : '/api/authers?populate=*&locale=es-MX',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetAllAuthorsQuery } = authorApi
