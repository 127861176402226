import React from 'react'

const StopVideoIcon: React.FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="7.7193" height="22" fill="white" />
      <rect x="14.2807" width="7.7193" height="22" fill="white" />
    </svg>
  )
}

export default StopVideoIcon
